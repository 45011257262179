import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  primary: {
    backgroundColor: theme.palette.elements.light,
    color: theme.palette.text.primary,
    borderRadius: '10px',
    width: '37px',
    height: '37px'
  },
  warning: {
    backgroundColor: theme.palette.elements.white,
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    width: '37px',
    height: '37px'
  },
  disabled: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.elements.white,
    width: '37px',
    height: '37px'
  }
}))
