import React, { useState } from 'react'
import {
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Avatar
} from '@mui/material'
import { ExitToApp, PictureAsPdf, Refresh } from '@mui/icons-material'
import { format } from '@utils/date-fns_functions'

import { useTranslation } from '@contexts/translation'

import useTableStyles from '@styles/table/table'
import {
  // getInvoiceGeneratePdf,
  downloadFile,
  onDownloadInvoicePDF
} from '@api/invoices'
import { useSnackbar } from 'notistack'
import { mutate } from 'swr'
import PlaceHolder from '@components/common/PlaceHolder'
import { responseHandler } from '@utils/responseHandler'

const Invoice = ({ invoiceData, preview, fetchInvoiceUrl }) => {
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const generatePdf = async () => {
    setLoading(true)
    // const response = await getInvoiceGeneratePdf(invoiceData?.id)
    const response = await onDownloadInvoicePDF(
      invoiceData?.id,
      invoiceData?.code
    )

    responseHandler({
      response,
      callback: async () => await mutate(fetchInvoiceUrl),
      dictionary,
      msgSuccess: dictionary.invoice_generated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Stack height={'80vh'}>
          {preview && preview !== 'error' && (
            <iframe
              src={preview}
              height="100%"
              width="100%"
              frameBorder="1"
              allowFullScreen
              target="_parent"
            />
          )}

          {preview && preview === 'error' && (
            <PlaceHolder
              src="/Placeholders/7_tags_etiquette.png"
              width={500}
              height={500}
              wordingBordered={dictionary.invoice_error_generate}
            />
          )}
        </Stack>
      </Grid>
      <Grid container item xs={3} spacing={3} sx={{ height: 'fit-content' }}>
        <Grid item xs={12} textAlign="right">
          <Button
            startIcon={<Refresh />}
            onClick={() => generatePdf()}
            disabled={loading}
            data-testid="Button-e3906fb0-89ce-4f72-a204-a88bf18d861e"
          >
            {dictionary.generate_pdf}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            component={({ children }) => (
              <Paper className={classesTable.container}>{children}</Paper>
            )}
          >
            <Table>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.history}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!invoiceData?.pdfs?.length && (
                  <TableRow className={classesTable.row}>
                    <TableCell align="center">{dictionary.no_pdf}</TableCell>
                  </TableRow>
                )}

                {invoiceData?.pdfs?.map((pdf, i) => (
                  <TableRow key={i} className={classesTable.row}>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar>
                          <PictureAsPdf fontSize="small" />
                        </Avatar>
                        <Stack>
                          <Typography
                            variant="subtitle2"
                            data-testid="Typography-25545376-1834-4eb3-8ec4-537b2496cd36"
                          >
                            {pdf.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            data-testid="Typography-2634fdd6-ea12-4cd9-90f5-c4c14eea3456"
                          >
                            {pdf.created_at
                              ? format(
                                  new Date(pdf.created_at),
                                  'dd/MM/yyyy, HH:mm'
                                )
                              : ''}
                          </Typography>
                        </Stack>
                        <a
                          target="_blank"
                          onClick={() =>
                            downloadFile(pdf.name, pdf.pk, invoiceData?.org)
                          }
                          onKeyDown={event => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              event.preventDefault()
                              event.stopPropagation()
                              downloadFile(pdf.name, pdf.pk, invoiceData?.org)
                            }
                          }}
                          rel="noreferrer"
                        >
                          <ExitToApp
                            color="primary"
                            fontSize="small"
                            style={{ cursor: 'pointer' }}
                          />
                        </a>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Invoice
