import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'

import Navigation from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs/Navigation'
import Invoice from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs/ContentTabs/Invoice'
import InvoiceReportsPDF from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs/ContentTabs/InvoiceReportsPDF'
import { postReportPreviewOrg } from '@api/reports'
import { useAuth } from '@contexts/auth'
import queryString from '@utils/queryString'
import { responseHandler } from '@utils/responseHandler'
import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'

const Pdfs = ({
  contractorsEnabled,
  invoiceData,
  fetchInvoiceUrl,
  projectId,
  project,
  preview
}) => {
  const { organization, organizationTeam } = useAuth()
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [view, setView] = useState({ id: 0, name: dictionary.invoice })
  const [previewPDF, setPreviewPDF] = useState(null)
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState({
    font_size: '14',
    orientation: 'portrait'
  })

  const handleView = ({ id, name }) => {
    setView({ id, name })
  }

  const queryPreview = useMemo(() => {
    const getType = id => {
      switch (id) {
        case 0:
          return 'invoices'
        case 1:
          return 'invoice_repartition_of_payments'
        case 2:
          return 'invoice_co_contract_statuses'
        case 3:
          return 'price_revisions'
        case 4:
        case 5:
          return 'invoice_sub_contract_statuses'
        case 6:
          return 'operation_balance'
        default:
          return ''
      }
    }

    return queryString({
      org: organization,
      invoice: invoiceData?.pk,
      team: invoiceData?.team || organizationTeam || '',
      type: getType(view.id),
      operation: projectId,
      font_size: properties.font_size,
      orientation: properties.orientation,
      name: getType(view.id)
    })
  }, [
    organization,
    invoiceData?.pk,
    organizationTeam,
    view.id,
    projectId,
    properties.font_size,
    properties.orientation,
    invoiceData?.team
  ])

  useEffect(() => {
    const createPreview = async () => {
      if (invoiceData?.pk && projectId) {
        setLoading(true)
        const response = await postReportPreviewOrg(organization, queryPreview)

        responseHandler({
          response,
          callback: async () => {
            const uint8View = new Uint8Array(response.data)
            setPreviewPDF(uint8View)
          },
          callbackError: () => setPreviewPDF('error'),
          dictionary,
          snackbar: enqueueSnackbar
        })
        setLoading(false)
      }
    }
    createPreview()
  }, [
    invoiceData?.pk,
    projectId,
    view.id,
    properties,
    dictionary,
    enqueueSnackbar,
    organization,
    queryPreview
  ])

  return (
    <Grid container>
      <Grid
        item
        xs={2}
        sx={{ borderRight: t => `1px solid ${t.palette.other.stroke}` }}
      >
        <Navigation
          view={view.id}
          handleNavigation={handleView}
          project={project}
          invoiceType={invoiceData?.type}
          contractorsEnabled={contractorsEnabled}
          invoiceData={invoiceData}
        />
      </Grid>
      <Grid item xs={10} p={3}>
        {view.id === 0 && (
          <Invoice
            invoiceData={invoiceData}
            preview={preview}
            fetchInvoiceUrl={fetchInvoiceUrl}
          />
        )}
        {view.id > 0 && (
          <InvoiceReportsPDF
            previewPDF={previewPDF}
            loading={loading}
            setProperties={setProperties}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Pdfs
