import { useEffect, useState } from 'react'
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Stack,
  CircularProgress,
  Typography
} from '@mui/material'
import {
  Delete,
  ReportProblem,
  AutoFixHigh as AutoFixHighIcon,
  Update as UpdateIcon
} from '@mui/icons-material'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import {
  deleteInvoiceContractItem,
  patchInvoiceContractItem,
  postInvoiceContractGenerateItem,
  postInvoiceSyncContractInvoiceProgress
} from '@api/invoices'

import PlaceHolder from '@components/common/PlaceHolder'

import numberWithCommas from '@utils/numberWithCommas'
import queryString from '@utils/queryString'

import useTableStyles from '@styles/table/table'
import {
  DraggableComponent,
  DroppableComponent,
  onDragEnd
} from '@utils/dragAndDrop'
import DeleteModal from '@components/common/DeleteModal'
import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import getTotal from '@utils/getTotal'
import { responseHandler } from '@utils/responseHandler'

const ContractorsTable = ({
  invoiceId,
  isSent,
  contractorId,
  toMutate,
  invoiceData
}) => {
  const classesTable = useTableStyles()
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [dragList, setDragList] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDelete, setIsDelete] = useState({ open: false, id: null })
  const [isGenerate, setIsGenerate] = useState(false)
  const [isSync, setIsSync] = useState(false)

  const contractItemsURL =
    API_URL.INVOICES.GET_CONTRACT_ITEMS(invoiceId) +
    '?' +
    queryString({ contractor: contractorId })
  const { data: contractItems, isLoading: isLoadingData } =
    useFetchData(contractItemsURL)

  useEffect(() => {
    if (contractItems) setDragList(contractItems.results)
  }, [contractItems])

  const handleGenerateContractorInvoicing = async () => {
    setLoading(true)
    const response = await postInvoiceContractGenerateItem(invoiceId)

    responseHandler({
      response,
      callback: async () => {
        await mutate(toMutate)
        await mutate(contractItemsURL)
      },
      dictionary,
      msgSuccess: dictionary.confirm_generate_action_success,
      snackbar: enqueueSnackbar
    })

    setIsGenerate(false)
    setLoading(false)
  }

  const handleSyncProgress = async () => {
    setLoading(true)
    const payload = { contractor: contractorId }
    const response = await postInvoiceSyncContractInvoiceProgress(
      invoiceId,
      payload
    )

    responseHandler({
      response,
      callback: async () => {
        await mutate(toMutate)
        await mutate(contractItemsURL)
      },
      dictionary,
      msgSuccess: dictionary.teams_message.update_message,
      snackbar: enqueueSnackbar
    })

    setIsSync(false)
    setLoading(false)
  }

  const handleUpdate = async (id, payload) => {
    const response = await patchInvoiceContractItem(id, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(toMutate)
        mutate(contractItemsURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })
  }

  const onConfirmDelete = async () => {
    setLoading(true)
    const response = await deleteInvoiceContractItem(isDelete.id)

    responseHandler({
      response,
      callback: async () => await mutate(contractItemsURL),
      dictionary,
      msgSuccess: dictionary.element_deleted,
      snackbar: enqueueSnackbar
    })

    setIsDelete({ open: false, id: null })
    setLoading(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} textAlign="right">
        <FilterActionsSelector
          isMenu
          options={[
            {
              label: dictionary.actions,
              handleClick: () => null
            },
            {
              label: dictionary.generate_items,
              icon: <AutoFixHighIcon color="secondary" />,
              handleClick: () => setIsGenerate(true)
            },
            {
              label: dictionary.sync_progress,
              icon: <UpdateIcon color="secondary" />,
              handleClick: () => setIsSync(true)
            }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table>
            <TableHead>
              <TableRow>
                {!isSent && <TableCell />}
                <TableCell className={classesTable.headerCell}>
                  {dictionary.description}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.fees}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.prev}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.prev}. {dictionary.billed}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.billable}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.curr}.
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.amount}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody
              component={DroppableComponent(
                res =>
                  onDragEnd(
                    res,
                    dragList,
                    setDragList,
                    patchInvoiceContractItem
                  ),

                dragList?.length > 0 && (
                  <TableRow hover={false} className={classesTable.rowTotal}>
                    {!isSent && <TableCell />}
                    <TableCell colSpan={2} align="right">
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        data-testid="Typography-320bf269-6dbd-44f1-8f71-f02e5b2bf3b9"
                      >
                        {numberWithCommas(
                          getTotal(dragList, 'total_fees') || 0,
                          2
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        data-testid="Typography-b4ce4cbb-fe27-4d24-a477-cab5e4fbd335"
                      >
                        {numberWithCommas(
                          getTotal(dragList, 'past_billed_amount') || 0,
                          2
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        data-testid="Typography-f15a0a7d-067d-4bb7-9b87-bbfc31767e30"
                      >
                        {numberWithCommas(
                          getTotal(dragList, 'billable') || 0,
                          2
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        color="secondary"
                        data-testid="Typography-da36ee9a-fdaa-47a7-ac2b-591cf0cdd1d9"
                      >
                        {numberWithCommas(getTotal(dragList, 'amount') || 0, 2)}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )
              )}
            >
              {isLoadingData && (
                <TableRow className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}

              {!isLoadingData && dragList && !dragList.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell colSpan={'100%'} align="center">
                    <PlaceHolder />
                  </TableCell>
                </TableRow>
              )}

              {!isLoadingData &&
                dragList &&
                dragList.map((element, index) => (
                  <TableRow
                    key={element.id}
                    className={classesTable.row}
                    component={DraggableComponent(
                      element.id.toString(),
                      index,
                      classesTable.row,
                      '#fff',
                      !isSent
                    )}
                  >
                    <TableCell>{element.display}</TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                      >
                        {element.total_fees === 0 && (
                          <ReportProblem color="warning" />
                        )}

                        {numberWithCommas(element.total_fees || 0, 2)}
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(element.previous_progress || 0, 2)}%
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(element.past_billed_amount || 0, 2)}
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(element.billable || 0, 2)}
                    </TableCell>
                    <TableCell align="right">
                      <CommonPopoverForm
                        type="number"
                        disabled={invoiceData?.is_sent}
                        variant="changeOptionDark"
                        buttonLabel={`${numberWithCommas(
                          element.current_progress || 0,
                          2
                        )}%`}
                        propertyLabel={dictionary.curr}
                        defaultValue={element.current_progress.toFixed(2) || 0}
                        onSubmit={values =>
                          handleUpdate(element.id, {
                            current_progress: values.textPopover
                          })
                        }
                        data_testid="CommonPopoverForm-ed5a228a-b8b9-4caa-b92e-7fc997fba3e3"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <CommonPopoverForm
                        type="number"
                        disabled={invoiceData?.is_sent}
                        variant="changeOptionDark"
                        buttonLabel={`${numberWithCommas(
                          element.amount || 0,
                          2
                        )}`}
                        propertyLabel={dictionary.amount}
                        defaultValue={element.amount.toFixed(2) || 0}
                        onSubmit={values =>
                          handleUpdate(element.id, {
                            amount: values.textPopover
                          })
                        }
                        data_testid="CommonPopoverForm-3f3b6f45-4a8a-43d5-a1af-8983d75a9a34"
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classesTable.rowActions}
                    >
                      <IconButton
                        size="small"
                        onClick={() =>
                          setIsDelete({ open: true, id: element.id })
                        }
                        data-testid="IconButton-badbbf16-5c4c-4565-a5e0-6acc2f0b5042"
                      >
                        <Delete fontSize="small" color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <DeleteModal
            open={isDelete.open}
            onClose={() => setIsDelete({ open: false, id: null })}
            onConfirm={onConfirmDelete}
            description={dictionary.confirm_to_delete}
            loading={loading}
          />

          <DeleteModal
            open={isGenerate}
            onClose={() => setIsGenerate(false)}
            onConfirm={handleGenerateContractorInvoicing}
            description={dictionary.confirm_to_generate_contract_invoice_items}
            loading={loading}
            type="confirm"
          />

          <DeleteModal
            open={isSync}
            onClose={() => setIsSync(false)}
            onConfirm={handleSyncProgress}
            description={dictionary.confirm_to_sync_contract_invoice_items}
            loading={loading}
            type="confirm"
          />
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ContractorsTable
