import { DragHandle } from '@mui/icons-material'
import { TableBody, TableRow, TableCell } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export const getItemStyle = (snapshot, draggableStyle, bg = '#fff') => {
  return {
    ...draggableStyle,
    background: bg,
    display: snapshot.isDragging ? 'table' : '',
    left: 'auto !important',
    top: draggableStyle.top,
    boxShadow: snapshot.isDragging ? '0 5px 18px 5px rgba(0, 0, 0, 0.25)' : ''
  }
}

export const DroppableComponent =
  (onDragEnd, additionnalRow) =>
  ({ children }) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={'opportunities-list-array'}
          direction="vertical"
        >
          {(provided, snapshot) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? 'lightgrey'
                    : '#fff'
                }}
              >
                {children}
                {provided.placeholder}
                {additionnalRow}
              </TableBody>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

export const DraggableComponent =
  (
    id,
    index,
    className,
    bg = '#fff',
    dragHandleVisible = true,
    dragHandleDisabled = false
  ) =>
  ({ children }) => {
    return (
      <Draggable
        draggableId={id}
        index={index}
        isDragDisabled={dragHandleDisabled}
      >
        {(provided, snapshot) => {
          return (
            <TableRow
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              style={getItemStyle(snapshot, provided.draggableProps.style, bg)}
              className={className}
              hover={false}
            >
              {dragHandleVisible && (
                <TableCell
                  {...provided.dragHandleProps}
                  sx={{ width: t => t.spacing(3) }}
                >
                  <DragHandle
                    color={dragHandleDisabled ? 'disabled' : 'primary'}
                  />
                </TableCell>
              )}
              {children}
            </TableRow>
          )
        }}
      </Draggable>
    )
  }

export const onDragEnd = async (
  result,
  items,
  setItems,
  patchFct,
  mutate = null
) => {
  if (!result.destination) return

  const backupList = [...items]
  const listCopy = [...items]

  const newOrderId = listCopy[result.destination.index].order
  const oldOrderId = listCopy[result.source.index].order

  const [removedElement] = listCopy.splice(result.source.index, 1)
  listCopy.splice(result.destination.index, 0, removedElement)

  setItems(listCopy)

  const response = await patchFct(result.draggableId, {
    order: newOrderId,
    position: newOrderId > oldOrderId ? 'below' : 'above'
  })
  if (!response || (response?.status !== 200 && response?.status !== 201)) {
    setItems(backupList)
    return
  }
  mutate && mutate()
}
