import React from 'react'
import { ListItemButton, ListItemIcon, Typography, List } from '@mui/material'
import { Groups, Person } from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'

import { MenuTitle } from '@components/Header'

const MenuItemCustom = ({ handleClick, isSelected, title, icon }) => {
  const _handleClick = () => handleClick()

  return (
    <ListItemButton
      onClick={_handleClick}
      selected={isSelected}
      sx={{
        pl: 0,
        py: '8px',
        '&.Mui-selected': {
          '&:hover': {
            background: theme => theme.palette.secondary.light
          },
          background: theme => theme.palette.secondary.light,

          boxShadow: theme =>
            `inset -2px 0px 0px ${theme.palette.secondary.main}`
        }
      }}
      data-testid="ListItemButton-6f3ba5a1-22ef-4803-b1fc-29655053850c"
    >
      <ListItemIcon
        sx={{
          minWidth: 'unset',
          pl: '28px',
          mr: '12px',
          color: isSelected ? 'secondary.main' : 'text.primary'
        }}
      >
        {icon}
      </ListItemIcon>
      <Typography
        variant={isSelected ? 'subtitle2' : 'body2'}
        color={isSelected ? 'secondary.main' : 'text.primary'}
        data-testid="Typography-8ecf49a6-a2f9-4849-9dff-30bbf186cad3"
      >
        {title}
      </Typography>
    </ListItemButton>
  )
}

const Navigation = ({
  activeId,
  handleSelect,
  coContractorsData,
  subContractorsData
}) => {
  const { dictionary } = useTranslation()

  return (
    <List>
      <MenuItemCustom
        title={dictionary.summary}
        isSelected={activeId === 0}
        handleClick={() => handleSelect(0, null)}
        icon={<Groups />}
      />

      {coContractorsData?.length > 0 && (
        <>
          <MenuTitle title={dictionary.co_contractors} needMargin={false} />

          {coContractorsData.map(item => {
            const isSelected = item.id === activeId
            return (
              <MenuItemCustom
                key={item.id}
                title={item.name || '---'}
                isSelected={isSelected}
                handleClick={() => handleSelect(item.id)}
                icon={<Person />}
              />
            )
          })}
        </>
      )}

      {subContractorsData?.length > 0 && (
        <>
          <MenuTitle title={dictionary.sub_contractors} needMargin={false} />

          {subContractorsData.map(item => {
            const isSelected = item.id === activeId
            return (
              <MenuItemCustom
                key={item.id}
                title={item.name || '---'}
                isSelected={isSelected}
                handleClick={() => handleSelect(item.id)}
                icon={<Person />}
              />
            )
          })}
        </>
      )}
    </List>
  )
}

export default Navigation
