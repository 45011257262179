import { Grid, Stack, MenuItem } from '@mui/material'

import { useTranslation } from '@contexts/translation'

import TextFieldItem from '@components/common/Form/TextFieldItem'
import Select from '@components/common/SelectUncontrolled'
import PlaceHolder from '@components/common/PlaceHolder'
import { PDFSkeleton } from '@components/common/Skeletons'

const InvoiceReportsPDF = ({ previewPDF, loading, setProperties }) => {
  const { dictionary } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={2} justifyContent="flex-end">
        <Grid item xs={2}>
          <TextFieldItem
            type="number"
            defaultValue={'14'}
            onChange={e =>
              setProperties(prev => ({ ...prev, font_size: e.target.value }))
            }
            placeholder={dictionary.font_size}
            label={dictionary.font_size}
            data_testid="TextFieldItem-3aee11c9-ae14-426a-9801-1c1fb1c0bb69"
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            fullWidth
            label={dictionary.orientation}
            defaultValue={'portrait'}
            onChangeEvent={e =>
              setProperties(prev => ({ ...prev, orientation: e.target.value }))
            }
            data_testid="Select-0192b90b-4ec6-429c-95c2-c364b502a048"
          >
            <MenuItem
              value={'portrait'}
              data-testid="MenuItem-1697a96c-c1d7-4e2f-bd6f-bc115967ef2c"
            >
              {dictionary.portrait}
            </MenuItem>
            <MenuItem
              value={'landscape'}
              data-testid="MenuItem-e5b67d9d-5123-43b8-9666-8fc4a8d9e8e2"
            >
              {dictionary.landscape}
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loading && <PDFSkeleton />}
        {!loading && (
          <Stack height={'80vh'}>
            {previewPDF && previewPDF !== 'error' && (
              <iframe
                src={previewPDF}
                height="100%"
                width="100%"
                frameBorder="1"
                allowFullScreen
              />
            )}

            {previewPDF && previewPDF === 'error' && (
              <PlaceHolder
                src="/Placeholders/7_tags_etiquette.png"
                width={500}
                height={500}
                wordingBordered={dictionary.file_too_heavy}
              />
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}

export default InvoiceReportsPDF
