import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.elements.main,
    color: theme.palette.text.primary,
    border: 0,
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.elements.white,
      border: 0
    }
  },

  selected: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.elements.white,
    border: 0
  }
}))
