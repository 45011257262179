import { Button, Grid } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import TextFieldItem from '@components/common/Form/TextFieldItem'
import { useTranslation } from '@contexts/translation'

const MarkAsNotSentForm = ({ updateInvoice, onClose }) => {
  const { dictionary } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: { not_sent_notes: '', is_sent: false }
  })

  const onSubmit = async values => {
    updateInvoice({ not_sent_notes: values.not_sent_notes, is_sent: false })
    onClose()
  }

  return (
    <form
      data-cy={'form'}
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="not_sent_notes"
            control={control}
            render={props => (
              <TextFieldItem
                required
                multiline
                placeholder={dictionary.explain_mark_as_not_sent}
                label={dictionary.explain_mark_as_not_sent}
                errorMessage={errors.not_sent_notes?.message}
                {...props}
                data_testid="TextFieldItem-c71edeed-f120-4401-a306-15a3860c2674"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            type="submit"
            data-testid="Button-08c4014d-b9fe-4733-9384-07ee7ed31c34"
          >
            {dictionary.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default MarkAsNotSentForm
