const getTotal = (array = [], field = null) => {
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  if (array && array.length > 0) {
    if (field) {
      return array
        .map(item => +getNestedValue(item, field) || 0)
        .reduce((a, b) => a + b, 0)
    } else {
      return array.reduce((a, b) => a + b, 0)
    }
  } else {
    return 0
  }
}

export default getTotal
