import { useEffect, useState } from 'react'
import {
  Button,
  List,
  ListItemButton,
  Popover,
  Typography,
  Stack,
  CircularProgress,
  Tooltip,
  Badge
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { MoreHoriz } from '@mui/icons-material'

import useButtonFilterStyles from '@styles/buttonFilter/buttonFilter'
import { ButtonSkeleton } from '@components/common/Skeletons'

const FilterActionsSelector = ({
  label = null,
  options = [],
  disabled = false,
  defaultIndex = 0,
  activeColor = true,
  customStyles = null,
  isMenu = false,
  startIcon, // = <Circle sx={{ color: 'transparent' }} />,
  isLoading = false,
  loadingAction = false,
  loaderColor = t => t.palette.primary.main,
  withBadge = false,
  badgeCount = 0,
  variant
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex)

  const classesButtonFilter = useButtonFilterStyles()

  useEffect(() => {
    setSelectedIndex(defaultIndex)
  }, [defaultIndex])

  const _handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const _handleClickItem = (option, idx) => {
    setSelectedIndex(idx)
    option.handleClick && option.handleClick()
    setAnchorEl(null)
    isMenu && setSelectedIndex(defaultIndex)
  }

  const _handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'more-actions-popover' : undefined

  let optionsCopy = [...options]
  if (isMenu) {
    optionsCopy.shift()
  }

  return !isLoading ? (
    <div>
      <Badge badgeContent={badgeCount || 0} color="primary">
        {(!withBadge || (withBadge && badgeCount > 0)) && (
          <Button
            // startIcon={
            //   loadingAction ? (
            //     <CircularProgress size={15} sx={{ color: loaderColor }} />
            //   ) : (
            //     startIcon
            //   )
            // }
            {...(startIcon && { startIcon })}
            // variant="outlined"
            aria-describedby={id}
            onClick={_handleClick}
            sx={
              !customStyles?.buttonRoot && !variant
                ? {
                    ml: 'auto',
                    '&:hover': {
                      backgroundColor: theme.palette.text.extralight,
                      borderColor:
                        selectedIndex && activeColor
                          ? theme.palette.warning.main
                          : theme.palette.dark.main
                    }
                  }
                : variant === 'primary'
                ? {
                    backgroundColor: theme => theme.palette.primary.main,
                    color: 'white',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: theme => theme.palette.primary.dark,
                      border: 'none'
                    }
                  }
                : customStyles.buttonRoot
            }
            className={
              (!customStyles &&
                !variant &&
                (selectedIndex && activeColor
                  ? classesButtonFilter.warning
                  : classesButtonFilter.primary)) ||
              ''
            }
            endIcon={
              loadingAction && (
                <CircularProgress size={15} sx={{ color: loaderColor }} />
              )
            }
            data-testid="Button-b80839ef-b4be-45e8-bbd8-b649b86eeb8f"
          >
            {/* {options[selectedIndex]?.label || label} */}
            {label || <MoreHoriz sx={{ width: 18, height: 18 }} />}
          </Button>
        )}
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={_handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List>
          {optionsCopy.map((option, idx) =>
            !option.hidden ? (
              <Tooltip
                key={`${option.label}-${idx}`}
                title={option.tooltip || ''}
              >
                <span data-testid="span-fc87b019-d782-49bf-a902-9782b9123a0d">
                  <ListItemButton
                    sx={
                      !customStyles?.itemRoot
                        ? {
                            '&.Mui-selected': {
                              backgroundColor: '#F2BB2E14',
                              boxShadow: 'none',
                              '&:hover': {
                                backgroundColor: '#F2BB2E1F'
                              }
                            }
                          }
                        : customStyles.itemRoot
                    }
                    selected={!isMenu && selectedIndex === idx}
                    onClick={() => _handleClickItem(option, idx)}
                    disabled={disabled || option?.disabled} // option.disabled for targetted disabling
                    data-testid="ListItemButton-3bafeffa-ed4e-4f0f-83b2-5c58a185ae08"
                  >
                    {!option?.downloadLink && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ width: '100%' }}
                      >
                        {option.icon || ''}
                        <Typography data-testid="Typography-c155c8b2-f414-494c-986c-aefa8b5140cb">
                          {option.label}
                        </Typography>
                      </Stack>
                    )}

                    {option?.downloadLink && (
                      <a
                        href={option?.downloadLink}
                        download
                        style={{ width: '100%' }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {option.icon || ''}
                          <Typography data-testid="Typography-5e0ea093-6ba2-4e25-8aa1-657ca2218fad">
                            {option.label}
                          </Typography>
                        </Stack>
                      </a>
                    )}
                  </ListItemButton>
                </span>
              </Tooltip>
            ) : undefined
          )}
        </List>
      </Popover>
    </div>
  ) : (
    <ButtonSkeleton />
  )
}

export default FilterActionsSelector
