import { useState } from 'react'
import { Grid, Typography, Box, Button, Stack } from '@mui/material'
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  WarningAmber as WarningAmberIcon
} from '@mui/icons-material'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from '@contexts/translation'
import { onDeleteInvoice } from '@api/invoices'

import DetailTable, {
  DetailTotalTable
} from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Summary/DetailTable'
import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'
import Activity from '@components/Invoicing/InvoicesTable/SummaryActivity'

import useTableStyles from '@styles/table/table'
import DeleteModal from '@components/common/DeleteModal'
import { PDFSkeleton } from '@components/common/Skeletons'
import PlaceHolder from '@components/common/PlaceHolder'
import { responseHandler } from '@utils/responseHandler'

const Summary = ({
  invoiceData,
  invoicesURL,
  invoicesCountsURL,
  preview,
  projectId,
  taxes,
  handleClose,
  canChange,
  isSent,
  getInvoicePDF,
  onConfirmCreditNote,
  taxCode,
  handleUpdateInvoiceField,
  isCreditNote,
  setIsCreditNote
}) => {
  const classesTable = useTableStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const onClose = () => {
    setIsDelete(false)
    setIsCreditNote(false)
  }

  const onConfirmDelete = async () => {
    setLoading(true)
    const response = await onDeleteInvoice(invoiceData.id)

    responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        await mutate(invoicesCountsURL)
      },
      dictionary,
      msgSuccess: dictionary.confirm_delete_action_success,
      snackbar: enqueueSnackbar
    })
    setLoading(false)
    onClose()
    handleClose()
  }

  return (
    <>
      <Grid item xs={3} p={3}>
        <Stack spacing={3}>
          <DetailTable
            canChange={canChange}
            isSent={isSent}
            invoiceData={invoiceData}
            handleUpdate={(field, value) =>
              handleUpdateInvoiceField({ [field]: value })
            }
            getInvoicePDF={getInvoicePDF}
          />

          <DetailTotalTable
            canChange={canChange}
            taxes={taxes}
            taxCode={taxCode}
            isSent={isSent}
            invoiceData={invoiceData}
            handleUpdate={(field, value) =>
              handleUpdateInvoiceField({ [field]: value })
            }
          />

          <Stack direction="row" justifyContent="space-between">
            <Button
              onClick={() => setIsDelete(true)}
              startIcon={<WarningAmberIcon />}
              variant="outlined"
              color="error"
              disabled={!canChange}
              data-testid="Button-e33c95c2-524f-4b4f-b0c9-b866c04904ec"
            >
              {dictionary.delete}
            </Button>
            {invoiceData?.type !== 9 && (
              <Button
                onClick={() => setIsCreditNote(true)}
                startIcon={<AccountBalanceWalletIcon />}
                variant="outlined"
                color="primary"
                disabled={!invoiceData?.is_valid}
                data-testid="Button-ad6721b5-915f-477c-ac30-71d45b7564a6"
              >
                {dictionary.credit_note}
              </Button>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        p={3}
        sx={{
          borderLeft: t => `1px solid ${t.palette.other.stroke}`,
          borderRight: t => `1px solid ${t.palette.other.stroke}`
        }}
      >
        {preview && preview !== 'error' && (
          <iframe
            src={preview + '#zoom=80'}
            height="100%"
            width="100%"
            frameBorder="1"
            allowFullScreen={true}
          />
        )}

        {preview && preview === 'error' && (
          <PlaceHolder
            src="/Placeholders/7_tags_etiquette.png"
            width={500}
            height={500}
            wordingBordered={dictionary.invoice_error_generate}
          />
        )}

        {!preview && <PDFSkeleton />}
      </Grid>
      <Grid
        container
        item
        xs={3}
        p={3}
        spacing={3}
        sx={{ height: 'fit-content' }}
      >
        {!!invoiceData.operation && (
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                data-testid="Typography-037c200e-c27e-4d19-8172-779f482d5bae"
              >
                {dictionary.notes}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={classesTable.container}
                p={2}
                sx={{
                  border: `1px solid ${theme.palette.other.stroke}`,
                  borderRadius: theme.spacing(1),
                  maxHeight: '800px'
                }}
              >
                <CommonPopoverForm
                  disabled={!canChange}
                  variant={'body1'}
                  propertyLabel={dictionary.note}
                  buttonLabel={invoiceData?.notes || dictionary.notes}
                  defaultValue={invoiceData?.notes || ''}
                  onSubmit={values =>
                    handleUpdateInvoiceField({
                      notes: values.textPopover
                    })
                  }
                  style={{ width: '100%' }}
                  isMultiline
                  data_testid="CommonPopoverForm-6f26855f-6d1a-429a-82ae-0bf6203552f6"
                />
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Activity projectId={projectId} invoiceId={invoiceData?.id} />
        </Grid>
      </Grid>

      <DeleteModal
        open={isDelete || isCreditNote}
        onClose={onClose}
        onConfirm={
          isDelete ? onConfirmDelete : isCreditNote ? onConfirmCreditNote : null
        }
        description={
          isDelete
            ? dictionary.delete_invoice_confirm_action
            : isCreditNote
            ? dictionary.credit_note_confirm
            : ''
        }
        loading={loading}
      />
    </>
  )
}

export default Summary
