import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { enUS, fr, de, it, es, nl, pt } from 'date-fns/locale'

import { FormControl, Stack, InputAdornment, Grid } from '@mui/material'
import { useTranslation } from '@contexts/translation'
import { DateRange } from 'react-date-range'
import CustomPopover from '@components/common/Popover'
import TextFieldItem from '@components/common/Form/TextFieldItem'
import { CalendarToday } from '@mui/icons-material'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

const DateRangePickerCustom = ({
  control,
  fullWidth,
  required,
  width,
  start_date = null,
  end_date = null,
  margin = 'normal',
  size = 'medium',
  setValue,
  disabled = false,
  ...props
}) => {
  const localeMap = {
    en: { locale: enUS, placeholder: 'dd/mm/yyyy' },
    fr: { locale: fr, placeholder: 'jj/mm/aaaa' },
    de: { locale: de, placeholder: 'tt/mm/jjjj' },
    it: { locale: it, placeholder: 'gg/mm/aaaa' },
    es: { locale: es, placeholder: 'dd/mm/aaaa' },
    nl: { locale: nl, placeholder: 'dd/mm/jjjj' },
    pt: { locale: pt, placeholder: 'jj/mm/aaaa' }
  }

  const formatDates = dateString => {
    if (!dateString) return
    const split = dateString.split('-')
    const day = split[0]
    const month = split[1]
    const year = split[2]
    return year + '-' + month + '-' + day
  }

  const { dictionary, lang } = useTranslation()
  const [isDates, setIsDates] = useState(false)
  const [endOpen, setEndOpen] = useState(false)
  const [state, setState] = useState([
    {
      startDate: start_date ? new Date(formatDates(start_date)) : new Date(),
      endDate: end_date ? new Date(formatDates(end_date)) : new Date(),
      key: 'selection',
      color: '#1AB394'
    }
  ])

  useEffect(() => {
    if (start_date && end_date) {
      setIsDates(true)
      setState([
        {
          startDate: new Date(formatDates(start_date)),
          endDate: new Date(formatDates(end_date)),
          key: 'selection',
          color: '#1AB394'
        }
      ])
    }
  }, [start_date, end_date])

  useEffect(() => {
    if (state[0].startDate && state[0].endDate) {
      setValue('start_date', state[0].startDate)
      setValue('end_date', state[0].endDate)
    }
  }, [state])

  return (
    <FormControl
      {...props}
      margin={margin}
      size={size}
      style={{ width: fullWidth ? '100%' : width || '195px' }}
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={6}>
          <Controller
            width="100%"
            name="start_date"
            control={control}
            render={() => (
              <CustomPopover
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                buttonLabel={
                  <TextFieldItem
                    required={required}
                    disabled={disabled}
                    value={
                      state[0].startDate && isDates
                        ? format(state[0].startDate, 'dd/MM/yyyy')
                        : ''
                    }
                    name="start_date"
                    label={dictionary.start_date}
                    placeholder={localeMap[lang].placeholder}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarToday color="primary" />
                        </InputAdornment>
                      )
                    }}
                    data_testid="TextFieldItem-d2c1b9f3-b807-433a-bcc4-f6c76c0e7d6c"
                  />
                }
              >
                <DateRange
                  initialFocusedRange={[0, 0]}
                  editableDateInputs={true}
                  locale={localeMap[lang]?.locale}
                  onChange={item => {
                    setState([item.selection])
                    setIsDates(true)
                  }}
                  moveRangeOnFirstSelection={true}
                  ranges={state}
                  dateDisplayFormat="dd/MM/yyyy"
                  startDatePlaceholder="dd/mm/yyyy"
                  endDatePlaceholder="dd/mm/yyyy"
                />
              </CustomPopover>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="end_date"
            control={control}
            render={() => (
              <CustomPopover
                setClosed={setEndOpen}
                closed={!endOpen}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                buttonLabel={
                  <TextFieldItem
                    placeholder={localeMap[lang].placeholder}
                    disabled={disabled}
                    required={required}
                    onClick={() => setEndOpen(true)}
                    value={
                      state[0].endDate && isDates
                        ? format(state[0].endDate, 'dd/MM/yyyy')
                        : ''
                    }
                    name="end_date"
                    label={dictionary.end_date}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarToday color="primary" />
                        </InputAdornment>
                      )
                    }}
                    data_testid="TextFieldItem-b8de0284-af14-4d7e-8aa3-6b5f1a891966"
                  />
                }
              >
                <DateRange
                  editableDateInputs={true}
                  initialFocusedRange={[0, 1]}
                  locale={localeMap[lang]?.locale}
                  onChange={item => {
                    setState([item.selection])
                    setEndOpen(false)
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  dateDisplayFormat="dd/MM/yyyy"
                  startDatePlaceholder="dd/mm/yyyy"
                  endDatePlaceholder="dd/mm/yyyy"
                />
              </CustomPopover>
            )}
          />
        </Grid>
      </Stack>
    </FormControl>
  )
}

export default DateRangePickerCustom

DateRangePickerCustom.propTypes = {
  control: PropTypes.object,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool
}
