// import Link from 'next/link'
import { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  TableHead,
  FormControlLabel,
  Switch,
  Stack,
  Typography,
  MenuItem
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import ReactHookFormEditor from '@components/common/Form/Editor/Editor'

// import routes from '@config/routes'

import { useTranslation } from '@contexts/translation'

import Select from '@components/common/Select'
import TextFieldItem from '@components/common/Form/TextFieldItem'
import { postNotifyContractors } from '@api/invoices'
import { postReportPreviewByQuery } from '@api/reports'

import useButtonTabsStyle from '@styles/buttonTabs/buttonTabs'
import useTableStyles from '@styles/table/table'
import { Email } from '@mui/icons-material'
import DeleteModal from '@components/common/DeleteModal'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { useAuth } from '@contexts/auth'
import queryString from '@utils/queryString'
import clsx from 'clsx'
import PlaceHolder from '@components/common/PlaceHolder'
import { responseHandler } from '@utils/responseHandler'

const NotifyCoContractors = ({
  invoiceId,
  contractors,
  onClose,
  invoiceData
}) => {
  const { organization, organizationTeam } = useAuth()
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classesTable = useTableStyles()
  const classesButtonTabs = useButtonTabsStyle()

  const [loading, setLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [previewPDF, setPreviewPDF] = useState(null)
  const [contractorTab, setContractorTab] = useState('invoicing')

  const [items, setItems] = useState([])

  const getReportPreview = async () => {
    let query = {}
    if (contractorTab === 'invoicing')
      query = {
        org: organization,
        invoice: invoiceData?.pk,
        team: organizationTeam,
        type: 'contractors',
        orientation: 'landscape',
        project: invoiceData?.project
      }
    else
      query = {
        org: organization,
        invoice: invoiceData?.pk,
        team: organizationTeam,
        type: 'invoice_contract_statuses',
        orientation: 'portrait',
        project: invoiceData?.project
      }

    const response = await postReportPreviewByQuery(queryString(query))

    responseHandler({
      response,
      callback: async () => {
        const fileBlob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(fileBlob)
        setPreviewPDF(fileURL)
      },
      dictionary,
      snackbar: enqueueSnackbar
    })
  }

  useEffect(async () => {
    getReportPreview()
  }, [contractorTab])

  const { data: templatesList } = useFetchData(
    API_URL.EMAILS.GET_EMAILS_LIST_PK +
      '?' +
      queryString({ type: 'contractor_notification', team: organizationTeam })
  )

  useEffect(() => {
    if (contractors) setItems(contractors.map(c => ({ ...c, selected: false })))
  }, [contractors])

  const defaultSubject = `${dictionary.invoicing_status}: ${invoiceData.project_display}`
  const defaultBody = `${dictionary.invoicing_status_body} .`

  const schema = yup.object().shape({
    subject: yup.string(),
    body: yup.string(),
    send_contractors: yup.boolean(),
    send_invoice_contract_statuses: yup.boolean(),
    invoice_email_template: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      subject: defaultSubject,
      email_body: defaultBody,
      send_contractors: true,
      send_invoice_contract_statuses: true,
      invoice_email_template: ''
    }
  })

  const emailTemplate = watch('invoice_email_template', '')
  const sendInvoice = watch('send_contractors', true)
  const sendDetails = watch('send_invoice_contract_statuses', true)

  useEffect(() => {
    const _template = templatesList?.results.find(t => t.id == emailTemplate)
    if (_template) {
      setValue('subject', _template?.email_subject)
      setValue('email_body', _template?.email_body)
    } else {
      setValue('subject', defaultSubject)
      setValue('email_body', defaultBody)
    }
  }, [emailTemplate])

  useEffect(() => {
    if (sendInvoice && !sendDetails) setContractorTab('invoicing')
    else if (!sendInvoice && sendDetails) setContractorTab('details')
    else if (!sendInvoice && !sendDetails) setPreviewPDF(null)
  }, [sendInvoice, sendDetails])

  const itemsSelected = items.filter(p => p.selected).map(p => p.id)
  const allItemsSelected = items.filter(p => p.selected).length === items.length
  const selectAllItems = () => {
    setItems(prev => prev.map(p => ({ ...p, selected: !allItemsSelected })))
  }
  const selectItem = id => {
    setItems(prev =>
      prev.map(p => (p.id !== id ? p : { ...p, selected: !p.selected }))
    )
  }

  const onSubmit = () => {
    setIsConfirm(true)
  }

  const onConfirm = async () => {
    const payload = {
      ...getValues(),
      body: `<div>${getValues('email_body')}</div>`,
      email_body: `<div>${getValues('email_body')}</div>`
    }

    setLoading(true)
    const response = await postNotifyContractors(invoiceId, {
      ...payload,
      pk: itemsSelected
    })

    responseHandler({
      response,
      dictionary,
      msgSuccess: dictionary.notification_sent,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    onClose()
  }

  return (
    <>
      <form
        data-cy={'form'}
        style={{ width: '100%' }}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3} item xs={12}>
          <Grid container spacing={3} item xs={4}>
            <Grid item xs={12}>
              <TableContainer className={classesTable.container}>
                <Table aria-label="collaboration-tasks-dense-table">
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell className={classesTable.headerCell}>
                        <Checkbox
                          checked={allItemsSelected}
                          onChange={selectAllItems}
                          indeterminate={
                            !allItemsSelected && itemsSelected?.length > 0
                          }
                          data-testid="Checkbox-b40ca7ad-b265-4efb-a235-da2fd53fa3e4"
                        />
                      </TableCell>
                      <TableCell className={classesTable.headerCell}>
                        {dictionary.company}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items?.map(item => (
                      <TableRow
                        key={item.id}
                        className={classesTable.row}
                        selected={item.selected}
                      >
                        <TableCell>
                          <Checkbox
                            checked={item.selected}
                            onChange={() => selectItem(item.id)}
                            data-testid="Checkbox-a1c45fc2-4950-4428-9e55-b7b2964b63e4"
                          />
                        </TableCell>
                        <TableCell>
                          <Stack>
                            <span data-testid="span-b3bca9ec-fcec-416b-ae73-7256e214b97a">
                              {item.name || '---'}
                            </span>
                            <Typography
                              variant="caption"
                              data-testid="Typography-2b537f45-a645-410d-8868-2c9141d82023"
                            >
                              {item.email}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {templatesList && templatesList.results.length > 0 && (
              <Grid item xs={12}>
                <Select
                  fullWidth
                  name="invoice_email_template"
                  label={dictionary.email_template}
                  variant="outlined"
                  control={control}
                  defaultValue={''}
                  error={Boolean(errors.invoice_email_template)}
                  errorMessage={errors.invoice_email_template?.message}
                  data_testid="Select-35d0f73d-f1ca-48e2-b6ba-847883960cd0"
                >
                  <MenuItem
                    value={''}
                    data-testid="MenuItem-2b2113f0-e6cc-412d-b60e-d2c143a8fee5"
                  >
                    {'----------'}
                  </MenuItem>
                  {templatesList &&
                    templatesList.results.map(({ id, name }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        data-testid="MenuItem-2f842f11-3286-431a-b526-edacf6f02bea"
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                name="subject"
                control={control}
                render={props => (
                  <TextFieldItem
                    label={dictionary.subject}
                    errorMessage={errors.subject?.message}
                    {...props}
                    data_testid="TextFieldItem-ff62c62a-8097-415b-bfe7-6109fc49c15e"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
                      <Controller
                        name="body"
                        control={control}
                        render={props => (
                          <TextFieldItem
                            multiline
                            label={dictionary.body}
                            errorMessage={errors.body?.message}
                            {...props}
                          />
                        )}
                      />
                      
                     </Grid> */}
            <Grid item xs={12}>
              <ReactHookFormEditor
                name="email_body"
                control={control}
                errorMessage={errors.editor?.message}
                image
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="send_contractors"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                        data-testid="Switch-b56e97e1-ece6-4b9b-848e-192a6f4da896"
                      />
                    }
                    label={dictionary.include_co_contractor_invoicing}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="send_invoice_contract_statuses"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                        data-testid="Switch-5c6426bc-5a5c-4af0-8dd2-16996686ea76"
                      />
                    }
                    label={dictionary.include_co_contractor_details}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Stack spacing={1} sx={{ height: '100%' }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  onClick={() => setContractorTab('invoicing')}
                  disabled={!sendInvoice}
                  className={clsx(classesButtonTabs.root, {
                    [classesButtonTabs.selected]: contractorTab === 'invoicing'
                  })}
                  data-testid="Button-8615e0b1-bcbc-4dc7-8f9c-0f5546d9897e"
                >
                  {`${dictionary.attachment} 1: ${dictionary.co_contractors_invoicing}`}
                </Button>
                <Button
                  onClick={() => setContractorTab('details')}
                  disabled={!sendDetails}
                  className={clsx(classesButtonTabs.root, {
                    [classesButtonTabs.selected]: contractorTab === 'details'
                  })}
                  data-testid="Button-45741900-31f0-4002-a5c3-85a3e6b43958"
                >
                  {`${dictionary.attachment} 2: ${dictionary.co_contractors_details}`}
                </Button>
              </Stack>

              {previewPDF && (
                <iframe
                  src={
                    previewPDF
                      ? previewPDF + '#zoom=80'
                      : 'https://ooti.co' + '#zoom=80'
                  }
                  height="100%"
                  width="100%"
                  frameBorder="1"
                  allowFullScreen
                />
              )}

              {!previewPDF && (
                <PlaceHolder
                  src="/Placeholders/3_Devis_estimate.png"
                  width={500}
                  height={500}
                  wording={dictionary.no_attachment}
                />
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} align="right">
            <Button
              startIcon={<Email />}
              type="submit"
              disabled={loading || !itemsSelected?.length}
              data-testid="Button-8d62c1bc-58a7-4292-9f6c-93de190943bf"
            >
              {dictionary.send_notification}
            </Button>
          </Grid>
        </Grid>
      </form>

      <DeleteModal
        open={isConfirm}
        onClose={() => setIsConfirm(false)}
        onConfirm={onConfirm}
        description={`${dictionary.confirm_to_send_mail_contractors}`}
        loading={loading}
        type="confirm"
      />
    </>
  )
}

export default NotifyCoContractors
