import { useState } from 'react'
import { Grid } from '@mui/material'

import Navigation from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Settings/Navigation'
import ClientForm from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Settings/Forms/Client'
import InvoiceForm from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Settings/Forms/Invoice'
import TaxForm from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Settings/Forms/Tax'
import PaymentForm from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Settings/Forms/Payment'
import { updateInvoice } from '@api/invoices'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useTranslation } from '@contexts/translation'
import { responseHandler } from '@utils/responseHandler'
import { format } from 'date-fns'

const Settings = ({
  invoiceData,
  invoicesURL,
  fetchInvoiceUrl,
  isSent,
  canChange,
  //taxCode,
  getInvoicePDF
  // taxes
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { dictionary } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [view, setView] = useState(0)

  const handleView = id => {
    setView(id)
  }

  const onSubmit = async values => {
    // let newValues = { ...values }
    // if ('tax_enabled' in values && !values.tax_enabled) {
    //   newValues = { ...values, multi_tax_enabled: false, tax_code: [] }
    // }
    setLoading(true)
    if (values.invoice_date) {
      values.invoice_date = format(new Date(values.invoice_date), 'dd-MM-yyyy')
    }
    if (values.start_date) {
      values.period_start_date = format(
        new Date(values.start_date),
        'dd-MM-yyyy'
      )
    }
    if (values.end_date) {
      values.period_end_date = format(new Date(values.end_date), 'dd-MM-yyyy')
    }
    const response = await updateInvoice(invoiceData.id, values)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesURL)
        if ('show_payments' in values || 'show_invoice_history' in values)
          getInvoicePDF()
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  return (
    <Grid container>
      <Grid
        item
        xs={2}
        sx={{ borderRight: t => `1px solid ${t.palette.other.stroke}` }}
      >
        <Navigation view={view} handleNavigation={handleView} />
      </Grid>
      <Grid item xs={10} p={3}>
        {view === 0 && (
          <ClientForm
            invoiceData={invoiceData}
            onSubmit={onSubmit}
            loading={loading}
            isSent={isSent}
            canChange={canChange}
          />
        )}
        {view === 1 && (
          <InvoiceForm
            invoiceData={invoiceData}
            onSubmit={onSubmit}
            loading={loading}
            isSent={isSent}
            canChange={canChange}
          />
        )}
        {view === 2 && (
          <TaxForm
            invoiceData={invoiceData}
            onSubmit={onSubmit}
            //taxCode={taxCode}
            loading={loading}
            isSent={isSent}
            canChange={canChange}
            //taxes={taxes}
          />
        )}
        {view === 3 && (
          <PaymentForm
            invoiceData={invoiceData}
            onSubmit={onSubmit}
            loading={loading}
            isSent={isSent}
            canChange={canChange}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Settings
