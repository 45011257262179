import { Button, ButtonGroup } from '@mui/material'
import useButtonGroupStyle from '@styles/buttonGroup/buttonGroup'
import clsx from 'clsx'
import { ButtonSkeleton } from '@components/common/Skeletons'
import Link from 'next/link'

const ButtonGroupTabs = ({
  tabs = [],
  sx = {},
  isLoading = false,
  fullWidth = false
}) => {
  const classesButtonGroup = useButtonGroupStyle()

  /*** tabs = [{ isVisible, startIcon, onClick, selected, text }] ***/
  return (
    <ButtonGroup size="small" sx={sx} fullWidth={fullWidth}>
      {tabs.map((tab, i) =>
        !isLoading ? (
          tab.isVisible &&
          (tab.link ? (
            <Link key={i} href={tab.link || ''} passHref>
              <Button
                startIcon={tab.startIcon || null}
                className={clsx(classesButtonGroup.root, {
                  [classesButtonGroup.selected]: tab.selected || false
                })}
                data-testid="Button-fb0338a5-7ee9-408a-ba1d-3946f99630fd"
              >
                {tab.text || ''}
              </Button>
            </Link>
          ) : (
            <Button
              key={i}
              startIcon={tab.startIcon || null}
              endIcon={tab.endIcon || null}
              className={clsx(classesButtonGroup.root, {
                [classesButtonGroup.selected]: tab.selected || false
              })}
              onClick={tab.onClick}
              data-testid="Button-85eb7383-3334-4aa4-b249-f316bc8952f8"
            >
              {tab.text || ''}
            </Button>
          ))
        ) : (
          <ButtonSkeleton borderRadius={0} key={i} />
        )
      )}
    </ButtonGroup>
  )
}

export default ButtonGroupTabs
