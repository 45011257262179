import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Grid, Button } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'

import DatePicker from '@components/form/DatePicker'
import TimePicker from '@components/form/TimePicker'

import { useTranslation } from '@contexts/translation'
import { updateInvoice } from '@api/invoices'
import { mutate } from 'swr'
import { responseHandler } from '@utils/responseHandler'

const ScheduleModal = ({
  invoiceData,
  handleClose,
  invoicesURL,
  fetchInvoiceUrl
}) => {
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    send_on_date: yup.string().required(dictionary.required),
    send_on_time: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      send_on_date: new Date(),
      send_on_time: new Date('01-01-1970 ' + '08:00:00')
    }
  })

  const onSubmit = async values => {
    setLoading(true)

    const payload = {
      send_on_date: format(new Date(values.send_on_date), 'dd-MM-yyyy'),
      send_on_time: format(new Date(values.send_on_time), 'HH:mm:ss'),
      is_scheduled: true,
      send_email: true
    }

    const response = await updateInvoice(invoiceData?.pk, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        await mutate(fetchInvoiceUrl)
      },
      dictionary,
      msgSuccess: dictionary.invoice_scheduled,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    handleClose && handleClose()
  }

  return (
    <form
      data-cy={'form'}
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DatePicker
            required
            name="send_on_date"
            label={dictionary.send_on_date}
            variant="outlined"
            fullWidth
            control={control}
            error={Boolean(errors.send_on_date)}
            errorMessage={errors.send_on_date && errors.send_on_date.message}
            data_testid="DatePicker-b9627e6e-cb7b-4213-816e-4c58a98b0eb1"
          />
        </Grid>
        <Grid item xs={12}>
          <TimePicker
            name="send_on_time"
            label={dictionary.send_on_time}
            variant="outlined"
            fullWidth
            control={control}
            error={Boolean(errors.send_on_time)}
            errorMessage={errors.send_on_time && errors.send_on_time.message}
          />
        </Grid>

        <Grid item xs={12} align="right">
          <Button
            type="submit"
            disabled={loading}
            data-testid="Button-3704c588-8184-4ea5-b288-aa3cae5b72c9"
          >
            {dictionary.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ScheduleModal
