import { Grid, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import API_URL from '@config/services'
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'

import Select from '@components/common/Select'
import { checkPerms } from '@utils/checkPerms'
import { LoadingButton } from '@mui/lab'

const PaymentForm = ({ invoiceData, onSubmit, loading, canChange }) => {
  const { dictionary } = useTranslation()
  const { organization, organizationTeam, permissions } = useAuth()

  const manage_banks = checkPerms({
    perm: ['manage_banks'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })

  const { data: banks } = useFetchData(
    manage_banks ? API_URL.BANKS.GET_LIST() : null
  )

  const { data: currencies } = useFetchData(
    API_URL.CURRENCIES.GET_CURRENCIES_LIST
  )

  const schema = yup.object().shape({
    bank: yup.string(),
    payment_type: yup.string(),
    show_payments: yup.boolean(),
    show_invoice_history: yup.boolean()
  })

  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      bank: invoiceData?.bank?.id || '',
      currency: invoiceData?.currency?.id || ''
      // payment_type: invoiceData.payment_type || '',
      // show_payments: invoiceData.show_payments || false,
      // show_invoice_history: invoiceData.show_invoice_history || false
    }
  })

  // const PAYMENT_TYPES = [
  //   {
  //     id: 1,
  //     label: dictionary.cash,
  //     value: 'E'
  //   },
  //   {
  //     id: 2,
  //     label: dictionary.check,
  //     value: 'C'
  //   },
  //   {
  //     id: 3,
  //     label: dictionary.card,
  //     value: 'U'
  //   },
  //   {
  //     id: 4,
  //     label: dictionary.lcr,
  //     value: 'L'
  //   },
  //   {
  //     id: 5,
  //     label: dictionary.transfer,
  //     value: 'V'
  //   },
  //   {
  //     id: 6,
  //     label: dictionary.deposit,
  //     value: 'P'
  //   },
  //   {
  //     id: 7,
  //     label: dictionary.none,
  //     value: 'S'
  //   }
  // ]

  return (
    <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            fullWidth
            name="bank"
            label={dictionary.bank}
            control={control}
            disabled={!canChange || !manage_banks}
            data_testid="Select-962adf29-e48c-417e-9d0f-72b2be502e39"
          >
            {banks?.results.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                data-testid="MenuItem-8f9b3422-631a-4374-bf03-ad1a5b138a5f"
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            name="currency"
            label={dictionary.currency}
            control={control}
            disabled={!canChange}
            data_testid="Select-d53ce6f2-224e-4b57-81bf-11b6190b8953"
          >
            <MenuItem
              value={''}
              data-testid="MenuItem-ae6f791b-bd99-4023-9bf6-e129047861a7"
            >
              {'----------'}
            </MenuItem>
            {currencies &&
              currencies.results.map(({ id, display }) => (
                <MenuItem
                  key={id}
                  value={id}
                  data-testid="MenuItem-0af27a50-34c2-4f95-9703-c284dc90c804"
                >
                  {display}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        {/* <Grid item xs={12}>
             <Select
               fullWidth
               name="payment_type"
               label={dictionary.Invoicing.payment_type}
               control={control}
             >
               <MenuItem value={''}>{'----------'}</MenuItem>
               {PAYMENT_TYPES.map(({ id, label, value }) => (
                 <MenuItem key={Math.random() + id} value={value}>
                   {label}
                 </MenuItem>
               ))}
             </Select>
            </Grid>
            <Grid item xs={12}>
             <Controller
               name="show_payments"
               control={control}
               defaultValue={false}
               render={({ field }) => (
                 <FormControlLabel
                   control={
                     <Switch
                       onChange={e => field.onChange(e.target.checked)}
                       checked={field.value}
                     />
                   }
                   label={dictionary.show_account_statement}
                 />
               )}
             />
            </Grid>
            <Grid item xs={12}>
             <Controller
               name="show_invoice_history"
               control={control}
               defaultValue={false}
               render={({ field }) => (
                 <FormControlLabel
                   control={
                     <Switch
                       onChange={e => field.onChange(e.target.checked)}
                       checked={field.value}
                     />
                   }
                   label={dictionary.show_invoice_history}
                 />
               )}
             />
            </Grid> */}

        <Grid item xs={12} textAlign="right">
          <LoadingButton
            type="submit"
            loading={loading}
            disabled={!canChange}
            data-testid="LoadingButton-27093309-debd-4e94-b6e8-ecf094a551dc"
          >
            {dictionary.save}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default PaymentForm
