import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

import { FormControl, TextField } from '@mui/material'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enUS, fr, de, it, es, nl, pt } from 'date-fns/locale'
import { useTranslation } from '@contexts/translation'

const TimePicker = ({
  name,
  label,
  control,
  error,
  id,
  errorMessage,
  fullWidth,
  required,
  disabled,
  clearable,
  width,
  margin = 'normal',
  size = 'medium',
  textFieldSize = 'small',
  inputFormat = 'HH:mm',
  ...props
}) => {
  const [value, setValue] = useState(null)
  const { lang } = useTranslation()

  const localeMap = {
    en: { locale: enUS },
    fr: { locale: fr },
    de: { locale: de },
    it: { locale: it },
    es: { locale: es },
    nl: { locale: nl },
    pt: { locale: pt }
  }

  return (
    <FormControl
      {...props}
      margin={margin}
      size={size}
      style={{ width: fullWidth ? '100%' : width || '195px' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap[lang]?.locale}
          >
            <MuiTimePicker
              value={value}
              disabled={disabled}
              inputFormat={inputFormat}
              clearable={clearable}
              onChange={newValue => {
                setValue(newValue)
              }}
              {...field}
              renderInput={params => (
                <TextField
                  {...params}
                  label={label}
                  id={id}
                  disabled={disabled}
                  name={name}
                  error={error}
                  helperText={errorMessage}
                  required={required}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  size={textFieldSize}
                  style={{ backgroundColor: '#fff' }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  )
}

export default TimePicker
