import { Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useTranslation } from '@contexts/translation'

import TextFieldItem from '@components/common/Form/TextFieldItem'
import { LoadingButton } from '@mui/lab'

const TaxForm = ({
  invoiceData,
  onSubmit,
  loading,
  isSent,
  canChange
  //taxes,
  //taxCode
}) => {
  const { dictionary } = useTranslation()

  const schema = yup.object().shape({
    // tax_enabled: yup.boolean(),
    // multi_tax_enabled: yup.boolean(),
    tax_rate: yup.number().positive().min(0).required(dictionary.required)
    //tax_code: yup.array()
  })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      // tax_enabled: invoiceData.tax_enabled,
      // multi_tax_enabled: invoiceData.multi_tax_enabled,
      tax_rate: invoiceData?.tax_rate || ''
      //tax_code: invoiceData.tax_code || []
    }
  })
  // useEffect(() => {
  //   if (taxCode) setValue('tax_code', [taxCode.id])
  // }, [taxCode])

  // useEffect(() => {
  //   if (invoiceData?.tax_enabled === false) {
  //     setValue('tax_code', [])
  //     setValue('multi_tax_enabled', false)
  //   }
  // }, [invoiceData])

  return (
    <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Controller
            name="tax_rate"
            control={control}
            render={props => (
              <TextFieldItem
                required
                disabled={!canChange}
                type="number"
                label={dictionary.tax_rate}
                errorMessage={errors.tax_rate?.message}
                {...props}
                data_testid="TextFieldItem-625bb1f1-f7b5-46ec-bd69-7fa18790fa64"
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={12}>
            <Controller
              control={control}
              name="tax_enabled"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isSent}
                      onChange={e => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label={dictionary.tax_enabled}
                />
              )}
            />
           </Grid> */}
        {/* {invoiceData?.tax_enabled && (
            <>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="multi_tax_enabled"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={isSent}
                          onChange={e => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                      label={dictionary.invoice_has_multiple_taxes}
                    />
                  )}
                />
              </Grid>
               <Grid item xs={12} md={4}>
                <Controller
                  name="tax_rate"
                  control={control}
                  render={props => (
                    <TextFieldItem
                      required
                      disabled={isSent}
                      type="number"
                      label={dictionary.tax_rate}
                      errorMessage={errors.tax_rate?.message}
                      {...props}
                    />
                  )}
                />
              </Grid>
            </>
           )} */}

        <Grid item xs={12} align="right">
          <LoadingButton
            type="submit"
            loading={loading}
            disabled={!canChange}
            data-testid="LoadingButton-48849b80-a741-477b-8f0e-03b654c635d2"
          >
            {dictionary.save}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default TaxForm
