import { Grid, MenuItem } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'

// import TextFieldItem from '@components/common/Form/TextFieldItem'
import Select from '@components/common/Select'
import DatePicker from '@components/form/DatePicker'
import DateRangePickerCustom from '@components/form/DateRangePicker'
// import unformatDate from '@utils/unformatDate'
import { LoadingButton } from '@mui/lab'
import TextFieldItem from '@components/common/Form/TextFieldItem'
import { useEffect } from 'react'
import { format } from 'date-fns'

const formatDates = dateString => {
  if (!dateString) return
  const split = dateString.split('-')
  const day = split[0]
  const month = split[1]
  const year = split[2]
  return year + '-' + month + '-' + day
}

const InvoiceForm = ({ invoiceData, onSubmit, loading, isSent, canChange }) => {
  const { dictionary, t } = useTranslation()
  // invoiceData['invoice_date'] = formatDates(invoiceData.invoice_date)
  // invoiceData['period_start_date'] = formatDates(invoiceData.period_start_date)
  // invoiceData['period_end_date'] = formatDates(invoiceData.period_end_date)

  // const { data: fetchLanguages } = useFetchData(API_URL.LANGUAGES.GET_LANGUAGES)
  const { data: styleguidesList } = useFetchData(
    API_URL.STYLEGUIDES.GET_STYLEGUIDES_PK
  )

  const { data: billingEntitiesList } = useFetchData(
    API_URL.BILLING_ENTITIES.GET_BILLING_ENTITIES_LIST
  )

  const schema = yup.object().shape({
    invoice_date: yup.string(),
    billing_option: yup.string(),
    language: yup.string(),
    styleguide: yup.string(),
    billing_entity: yup.string()
    // billing_option: yup.number(),
    // language: yup.string(),
    // mission: yup.string(),
    // additional_text: yup.string(),
    // styleguide: yup.string(),
    // invoice_template: yup.string(),
    // invoice_line_padding: yup.string().required(dictionary.required),
    // font_size: yup.string().required(dictionary.required),
    // use_old_template: yup.boolean(),
    // with_advance: yup.boolean()
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      invoice_date: invoiceData.invoice_date
        ? new Date(formatDates(invoiceData.invoice_date))
        : null,
      billing_option: invoiceData?.billing_option || '',
      document_type: invoiceData?.document_type || '',
      language: invoiceData?.language || '',
      styleguide: invoiceData.styleguide || '',
      legal_mentions: invoiceData?.legal_mentions || '',
      billing_entity: invoiceData?.billing_entity?.id || '',
      period_start_date: invoiceData?.period_start_date || null,
      period_end_date: invoiceData?.period_end_date || ''
      // city: invoiceData.city || '',
      // mission: invoiceData.mission || '',
      // additional_text: invoiceData.additional_text || '',
      // invoice_template: invoiceData.invoice_template || '',
      // invoice_line_padding: invoiceData.invoice_line_padding || '0',
      // font_size: invoiceData.font_size || '14',
      // use_old_template: invoiceData.use_old_template || false,
      // with_advance: invoiceData.with_advance || false
    }
  })

  const watchStyleguide = watch('styleguide')

  useEffect(() => {
    if (watchStyleguide) {
      if (watchStyleguide === invoiceData?.styleguide) {
        setValue('legal_mentions', invoiceData?.legal_mentions || '')
      } else {
        setValue(
          'legal_mentions',
          styleguidesList?.results.find(({ id }) => id === watchStyleguide)
            ?.legal_mentions || ''
        )
      }
    }
  }, [watchStyleguide])

  const BILLING_OPTION_ITEMS = [
    { value: '30_days', label: dictionary['30_days'] },
    { value: '45_days', label: dictionary['45_days'] },
    { value: '60_days', label: dictionary['60_days'] },
    { value: '75_days', label: dictionary['75_days'] },
    { value: '90_days', label: dictionary['90_days'] },
    { value: 'end_of_month', label: dictionary['end_of_month'] },
    { value: 'on_reception', label: dictionary['on_reception'] },
    { value: 'due_on', label: dictionary['due_on'] },
    { value: 'free', label: dictionary['free'] },
    { value: '5_days', label: dictionary['5_days'] },
    { value: '10_days', label: dictionary['10_days'] }
    // { id: 9, label: `7 ${dictionary.days}` },
    // { id: 13, label: `8 ${dictionary.days}` },
    // { id: 11, label: `10 ${dictionary.days}` },
    // { id: 8, label: `15 ${dictionary.days}` },
    // { id: 14, label: `21 ${dictionary.days}` },
    // { id: 1, label: `30 ${dictionary.days}` },
    // { id: 12, label: `40 ${dictionary.days}` },
    // { id: 2, label: `45 ${dictionary.days}` },
    // { id: 10, label: `50 ${dictionary.days}` },
    // { id: 3, label: `60 ${dictionary.days}` },
    // { id: 4, label: `75 ${dictionary.days}` },
    // { id: 5, label: `90 ${dictionary.days}` },
    // { id: 6, label: dictionary.Invoicing.end_of_next_month },
    // { id: 7, label: dictionary.Invoicing.payment_on_receipt },
    // { id: 15, label: dictionary.Invoicing.end_month_forty_five },
    // { id: 16, label: dictionary.Invoicing.end_of_current_month }
  ]

  // const INVOICE_TEMPLATE_ITEMS = [
  //   { id: 0, value: 'incremental', label: dictionary.Invoicing.incremental },
  //   { id: 1, value: 'cumulative', label: dictionary.cumulative },
  //   {
  //     id: 2,
  //     value: 'cumulative_detail',
  //     label: dictionary.Invoicing.cumulative_detailed
  //   },
  //   {
  //     id: 3,
  //     value: 'no_progress',
  //     label: dictionary.Invoicing.without_progress
  //   },
  //   {
  //     id: 4,
  //     value: 'only_amount',
  //     label: dictionary.Invoicing.without_progress_of_previous
  //   },
  //   {
  //     id: 5,
  //     value: 'billable_cumulative',
  //     label: dictionary.Invoicing.cumulative_billable_beta
  //   },
  //   { id: 6, value: 'only_billable', label: dictionary.Invoicing.amount_only },
  //   { id: 7, value: 'co_contractors', label: dictionary.billable }
  // ]

  const languages = [
    { code: 'en', display: dictionary.english },
    { code: 'fr', display: dictionary.french }
  ]

  const DOCUMENT_TYPES = [
    { id: 0, value: 'receipt', label: dictionary.receipt },
    { id: 1, value: 'invoice', label: dictionary.invoice }
  ]

  return (
    <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            fullWidth
            name="billing_entity"
            label={dictionary.billing_entity}
            control={control}
            disabled={!canChange}
            data_testid="Select-81e17db5-d1ea-44cc-8024-dce452ce67fa"
          >
            {billingEntitiesList?.results.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                data-testid="MenuItem-80d14b8d-b6cc-4945-8994-560e8a897859"
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            disabled={!canChange}
            name="invoice_date"
            label={dictionary.invoice_date}
            variant="outlined"
            fullWidth
            control={control}
            errorMessage={errors.invoice_date?.message}
            data_testid="DatePicker-534ccbf5-d83e-4249-8fc4-9d651e8481f8"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            name="billing_option"
            label={dictionary.invoice_period}
            control={control}
            disabled={!canChange}
            data_testid="Select-251a92ab-ba98-4838-b94b-6dbe04580a37"
          >
            {BILLING_OPTION_ITEMS.map(({ value, label }) => (
              <MenuItem
                key={value}
                value={value}
                data-testid="MenuItem-3221b1df-f331-4fbd-b7d2-d59eac5f3e75"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <DateRangePickerCustom
            name="date_range"
            disabled={!canChange}
            setValue={setValue}
            defaultValue={[null, null]}
            start_date={
              invoiceData?.period_start_date
                ? invoiceData?.period_start_date
                : format(new Date(), 'dd-MM-yyyy')
            }
            end_date={
              invoiceData?.period_end_date
                ? invoiceData?.period_end_date
                : format(new Date(), 'dd-MM-yyyy')
            }
            variant="outlined"
            fullWidth
            control={control}
            required
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            name="document_type"
            label={dictionary.document_type}
            control={control}
            disabled={!canChange}
            data_testid="Select-e3184490-43e0-4b69-bd55-20100eda9f90"
          >
            {DOCUMENT_TYPES.map(({ value, label }) => (
              <MenuItem
                key={value}
                value={value}
                data-testid="MenuItem-0ca72802-c961-40ba-96c8-ece2538a0d6c"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            name="language"
            label={dictionary.language}
            control={control}
            disabled={!canChange}
            data_testid="Select-45395210-c72b-46eb-8597-d2b2b294e4d7"
          >
            {languages.map(({ code, display }) => (
              <MenuItem
                key={code}
                value={code}
                data-testid="MenuItem-9fb3234c-b4b2-4a7a-bb70-806dde061499"
              >
                {display}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            name="styleguide"
            label={dictionary.styleguide}
            control={control}
            disabled={!canChange}
            data_testid="Select-dc9c32d7-e1b0-411c-85a0-235f0af9eb56"
          >
            {styleguidesList?.results.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                data-testid="MenuItem-334fe5cd-b85d-4b19-a678-2feb7634a2a7"
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {watchStyleguide && (
          <Grid item xs={12}>
            <Controller
              name="legal_mentions"
              control={control}
              render={props => (
                <TextFieldItem
                  disabled={!canChange}
                  multiline
                  maxRows={2}
                  placeholder={t(
                    'account_settings_general_styleguide_legal_notices'
                  )}
                  label={t('account_settings_general_styleguide_legal_notices')}
                  errorMessage={errors.legal_mentions?.message}
                  {...props}
                />
              )}
            />
          </Grid>
        )}
        {/* <Grid item xs={12}>
              <Select
                fullWidth
                name="billing_option"
                label={dictionary.Invoicing.payment_period}
                control={control}
                disabled={isSent}
              >
                {BILLING_OPTION_ITEMS.map(({ id, label }) => (
                  <MenuItem key={Math.random() + id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
             </Grid>
             <Grid item xs={6}>
              <Select
                fullWidth
                name="language"
                label={dictionary.language}
                control={control}
              >
                {fetchLanguages &&
                  fetchLanguages.languages.map(({ code, display }) => (
                    <MenuItem key={code} value={code}>
                      {display}
                    </MenuItem>
                  ))}
              </Select>
             </Grid>
             <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                render={props => (
                  <TextFieldItem
                    label={dictionary.city}
                    errorMessage={errors.invoice_city?.message}
                    {...props}
                  />
                )}
              />
             </Grid>
             <Grid item xs={12}>
              <Controller
                name="mission"
                control={control}
                render={props => (
                  <TextFieldItem
                    label={dictionary.mission}
                    errorMessage={errors.mission?.message}
                    {...props}
                  />
                )}
              />
             </Grid>
             <Grid item xs={12}>
              <Controller
                name="additional_text"
                control={control}
                render={props => (
                  <TextFieldItem
                    multiline
                    minRows={3}
                    label={dictionary.additional_text}
                    errorMessage={errors.additional_text?.message}
                    {...props}
                  />
                )}
              />
             </Grid>
             <Grid item xs={6}>
              <Select
                fullWidth
                name="styleguide"
                label={dictionary.styleguide}
                control={control}
              >
                {styleguidesList?.results.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
             </Grid>
             <Grid item xs={6}>
              <Select
                fullWidth
                name="invoice_template"
                label={dictionary.invoice_template}
                control={control}
              >
                {INVOICE_TEMPLATE_ITEMS.map(({ id, value, label }) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
             </Grid>
             <Grid item xs={6}>
              <Controller
                name="invoice_line_padding"
                control={control}
                render={props => (
                  <TextFieldItem
                    type="number"
                    required
                    label={dictionary.padding}
                    errorMessage={errors.invoice_line_padding?.message}
                    {...props}
                  />
                )}
              />
             </Grid>
             <Grid item xs={6}>
              <Controller
                name="font_size"
                control={control}
                render={props => (
                  <TextFieldItem
                    type="number"
                    required
                    label={dictionary.default_font_size_for_invoice_pdf}
                    errorMessage={errors.font_size?.message}
                    {...props}
                  />
                )}
              />
             </Grid>
             {false && (
              <>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="use_old_template"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={e => field.onChange(e.target.checked)}
                            checked={field.value}
                          />
                        }
                        label={dictionary.use_old_template}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="with_advance"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={e => field.onChange(e.target.checked)}
                            checked={field.value}
                          />
                        }
                        label={dictionary.with_advance}
                      />
                    )}
                  />
                </Grid>
              </>
             )} */}

        <Grid item xs={12} textAlign="right">
          <LoadingButton
            type="submit"
            loading={loading}
            disabled={!canChange}
            data-testid="LoadingButton-6c0f5057-8f16-41e6-a847-a48158ceb923"
          >
            {dictionary.save}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default InvoiceForm
