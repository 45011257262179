import React, { useEffect } from 'react'
import { Grid } from '@mui/material'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useTranslation } from '@contexts/translation'

import TextFieldItem from '@components/common/Form/TextFieldItem'
import { LoadingButton } from '@mui/lab'

const ClientForm = ({ invoiceData, onSubmit, loading, canChange }) => {
  const { dictionary } = useTranslation()

  const schema = yup.object().shape({
    client_name: yup.string(),
    client_address: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  useEffect(() => {
    if (invoiceData?.tenant) {
      setValue('client_name', invoiceData?.tenant?.display)
      setValue('client_address', invoiceData?.tenant?.address)
    } else {
      setValue('client_name', invoiceData?.client_name || '')
      setValue('client_address', invoiceData?.client_address || '')
    }
  }, [invoiceData])

  return (
    <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="client_name"
            control={control}
            render={props => (
              <TextFieldItem
                disabled={!canChange}
                placeholder={dictionary.client_name}
                label={dictionary.client_name}
                errorMessage={errors.client_name?.message}
                {...props}
                data_testid="TextFieldItem-879a4e5e-bedc-4f36-aa36-781fdbcad2e6"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="client_address"
            control={control}
            render={props => (
              <TextFieldItem
                disabled={!canChange}
                multiline
                mitrows={3}
                placeholder={dictionary.client_address}
                label={dictionary.client_address}
                errorMessage={errors.client_address?.message}
                {...props}
                data_testid="TextFieldItem-e1f58f61-a0da-4323-bb54-90625edf3e45"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} textAlign="right">
          <LoadingButton
            type="submit"
            loading={loading}
            disabled={!canChange}
            data-testid="LoadingButton-3befc860-b513-4b9a-a284-f96c2678aff5"
          >
            {dictionary.save}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default ClientForm
