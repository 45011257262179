import Link from 'next/link'
import {
  Stack,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Tooltip
} from '@mui/material'
import { Warning } from '@mui/icons-material'

import routes from '@config/routes'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'

import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'

import useTableStyles from '@styles/table/table'

import unformatDate from '@utils/unformatDate'
import numberWithCommas from '@utils/numberWithCommas'
import { postCustomfieldValues } from '@api/customfields'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useAuth } from '@contexts/auth'
import API_URL from '@config/services'
import queryString from '@utils/queryString'
import { format } from '@utils/date-fns_functions'
import { responseHandler } from '@utils/responseHandler'
import roundNumber from '@utils/roundNumber'

export const DetailTotalTable = ({
  invoiceData
  // handleUpdate,
  //canChange,
  //taxes,
  //taxCode,
  // isSent
}) => {
  const classesTable = useTableStyles()
  const { dictionary } = useTranslation()
  return (
    <TableContainer
      component={({ children }) => (
        <Paper className={classesTable.containerTotal}>{children}</Paper>
      )}
    >
      <Table aria-label="table_invoicing_invoices_summary_2">
        <TableBody>
          <TableRow
            hover={false}
            sx={{
              'td, th': { borderBottom: 0 }
            }}
            className={classesTable.rowTotal}
          >
            <TableCell>
              <Typography
                variant="subtitle2"
                color="secondary"
                data-testid="Typography-d8b2529e-58ca-41a8-8f44-055c9a0cc99c"
              >
                {dictionary.subtotal}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                variant="subtitle2"
                color="secondary"
                data-testid="Typography-30649a22-0c4d-4cd8-9511-2e5900863d48"
              >
                {numberWithCommas(
                  invoiceData?.subtotal,
                  2,
                  invoiceData?.currency?.symbol
                )}
              </Typography>
            </TableCell>
          </TableRow>
          {invoiceData?.tax_rates &&
            invoiceData.tax_rates.map(rate => (
              <TableRow
                hover={false}
                className={classesTable.rowTotal}
                key={rate.tax_rate}
              >
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-dda39d2b-1894-4834-84f7-ae34b15fdefa"
                  >
                    {dictionary.tax} ({roundNumber(rate.tax_rate)}%)
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-08750197-d615-451b-a35c-93917a0e3c5f"
                  >
                    {numberWithCommas(
                      rate.tax,
                      2,
                      invoiceData?.currency?.symbol
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}

          {/* {!invoiceData?.multi_tax_enabled && (
                <TableRow
                  hover={false}
                  className={classesTable.rowTotal}
                  sx={{ 'td, th': { borderBottom: 0 } }}
                >
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle2" color="secondary">
                        {dictionary.vat}
                      </Typography>
                      <Typography variant="subtitle2" color="secondary">
                        {invoiceData && !invoiceData.multi_tax_enabled && (
                          // <CommonPopoverForm
                          //   disabled={isSent}
                          //   style={{ marginLeft: '0' }}
                          //   variant={'changeOptionSec'}
                          //   buttonLabel={`${numberWithCommas(
                          //     taxCode?.tax_rate || 0,
                          //     1
                          //   )} %`}
                          //   propertyLabel={dictionary.tax_rate}
                          //   type="select"
                          //   defaultValue={taxCode?.id || ''}
                          //   onSubmit={values =>
                          //     handleUpdate('tax_code', [values.selectPopover])
                          //   }
                          // >
                          //   {taxes &&
                          //     taxes.map(tax => (
                          //       <MenuItem key={tax.id} value={tax.id}>
                          //         {tax.name} {tax.tax_rate} %
                          //       </MenuItem>
                          //     ))}
                          // </CommonPopoverForm>
                          <CommonPopoverForm
                            disabled={isSent}
                            variant={'changeOptionSec'}
                            buttonLabel={`(${numberWithCommas(
                              invoiceData?.tax_rate || 0,
                              1
                            )} %)`}
                            propertyLabel={dictionary.vat}
                            defaultValue={invoiceData?.tax_rate}
                            type="number"
                            onSubmit={values =>
                              handleUpdate('tax_rate', values.textPopover)
                            }
                          />
                        )}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2" color="secondary">
                      {numberWithCommas(invoiceData?.tax || 0, 2)}{' '}
                      {invoiceData?.base_currency_symbol}
                    </Typography>
                  </TableCell>
                </TableRow>
               )} */}
          <TableRow hover={false} className={classesTable.rowTotalDark}>
            <TableCell>
              <Typography
                variant="subtitle2"
                color="secondary.contrastText"
                data-testid="Typography-1cb7a846-f517-4785-b28d-57167a7db872"
              >
                {dictionary.total.toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                variant="subtitle2"
                color="secondary.contrastText"
                data-testid="Typography-5b84fcdc-05d8-47cf-b7dc-9c018d1b7e0d"
              >
                {/* {invoiceData?.currency.name}&nbsp; */}
                {numberWithCommas(
                  invoiceData?.amount,
                  2,
                  invoiceData?.currency?.symbol
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow hover={false} className={classesTable.rowTotal}>
            <TableCell>
              <Typography
                variant="subtitle2"
                color="secondary"
                data-testid="Typography-2e4d1324-5a31-464b-99d4-ca230567c6d3"
              >
                {dictionary.balance}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                variant="subtitle2"
                color="secondary"
                data-testid="Typography-b7b16ad4-38e3-41b8-9939-0a56f26f5ca8"
              >
                {/* {invoiceData?.currency.name}&nbsp; */}
                {numberWithCommas(
                  invoiceData?.balance,
                  2,
                  invoiceData?.currency?.symbol
                )}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const DetailTable = ({
  invoiceData,
  handleUpdate,
  canChange,
  isSent,
  getInvoicePDF
}) => {
  const { organization, organizationTeam } = useAuth()
  const classesTable = useTableStyles()
  const { dictionary, t, lang } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: banks } = useFetchData(
    API_URL.BANKS.GET_LIST() +
      '?' +
      queryString({
        team: organizationTeam,
        ...(invoiceData?.project ? { project: invoiceData?.project } : {})
      })
  )

  /* const { data: customFields } = useFetchData(
    API_URL.CUSTOMFIELDS.GET_CUSTOMFIELDS_FILTERED_LIST(organization, 'invoice')
  ) */
  let customFields

  const customFieldsValuesURL = invoiceData
    ? API_URL.CUSTOMFIELDS.GET_CUSTOMFIELDS_VALUES(organization) +
      '?' +
      queryString({ team: organizationTeam, invoice: invoiceData.pk })
    : null
  // const { data: customFieldsValues } = useFetchData(customFieldsValuesURL)
  let customFieldsValues

  const handleChnageInvoiceDate = values => {
    const formatedDate = format(values.calendarPopover, 'dd-MM-yyyy')

    handleUpdate('invoice_date', formatedDate)
  }

  // const INVOICE_TEMPLATE_ITEMS = []

  const BILLING_OPTION_ITEMS = [
    { value: '30_days', label: dictionary['30_days'] },
    { value: '45_days', label: dictionary['45_days'] },
    { value: '60_days', label: dictionary['60_days'] },
    { value: '75_days', label: dictionary['75_days'] },
    { value: '90_days', label: dictionary['90_days'] },
    { value: 'end_of_month', label: dictionary['end_of_month'] },
    { value: 'on_reception', label: dictionary['on_reception'] },
    { value: 'due_on', label: dictionary['due_on'] },
    { value: 'free', label: dictionary['free'] },
    { value: '5_days', label: dictionary['5_days'] },
    { value: '10_days', label: dictionary['10_days'] }
  ]

  const updateCustomField = async (cfId, value) => {
    if (customFields && customFields.results.length > 0) {
      const response = await postCustomfieldValues(
        organization,
        'invoice',
        invoiceData?.pk,
        { [cfId]: value }
      )

      responseHandler({
        response,
        callback: async () => {
          await mutate(customFieldsValuesURL)
          getInvoicePDF()
        },
        dictionary,
        msgSuccess: dictionary.Invoicing.invoice_updated,
        snackbar: enqueueSnackbar
      })
    }
  }
  return (
    <TableContainer
      component={({ children }) => (
        <Paper
          className={classesTable.container}
          sx={{ maxHeight: '100% !important' }}
        >
          {children}
        </Paper>
      )}
    >
      <Table aria-label="table_invoicing_invoices_summary">
        <TableBody>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-a7d92689-8664-4e69-adcb-583671a4e8c0"
              >
                {dictionary.code}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <CommonPopoverForm
                disabled={!canChange}
                variant={'changeOptionDark'}
                buttonLabel={invoiceData?.code || '---'}
                propertyLabel={dictionary.code}
                defaultValue={invoiceData?.code || ''}
                onSubmit={values => handleUpdate('code', values.textPopover)}
                data_testid="CommonPopoverForm-fb3e5341-8dc7-40e5-93b9-caa3fe9a4f24"
              />
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-2bdcac77-28ce-46f2-9bf5-170f22424aee"
              >
                {dictionary.project}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Link
                href={`${routes.project.summary}/${invoiceData?.operation?.id}`}
              >
                <a>
                  <Typography
                    variant="linkOption"
                    data-testid="Typography-66aa64e0-9e7b-4cae-9bc3-6d00267a1f13"
                  >
                    {invoiceData?.operation?.name}
                  </Typography>
                </a>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-20d4b848-a6cf-4285-bb83-0353b296206c"
              >
                {dictionary.client_name}
              </Typography>
            </TableCell>
            <TableCell align="right">{invoiceData?.client_name}</TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-fa780f7e-56a1-49ea-8963-f91514c47b42"
              >
                {dictionary.client_address}
              </Typography>
            </TableCell>
            <TableCell align="right">
              {invoiceData?.client_address || (
                <Tooltip title={t('invoicing_invoices_client_adress_warning')}>
                  <Warning color="warning" />
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-76cd394a-6722-4838-b2b1-b4be2b500048"
              >
                {dictionary.currency}
              </Typography>
            </TableCell>
            <TableCell align="right">{invoiceData?.currency?.name}</TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-0e8b7465-afdf-4330-8447-740f3ec6c396"
              >
                {dictionary.invoice_date}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Stack direction="row" justifyContent={'flex-end'}>
                <span data-testid="span-d2b42fc1-73b6-4241-a947-e771b7c39284">
                  <CommonPopoverForm
                    // disabled={isSent}
                    disabled={!canChange}
                    variant={'changeOptionDark'}
                    buttonLabel={invoiceData?.invoice_date || '---'}
                    propertyLabel={dictionary.invoice_date}
                    type="calendar"
                    defaultValue={unformatDate(invoiceData?.invoice_date)}
                    onSubmit={handleChnageInvoiceDate}
                    data_testid="CommonPopoverForm-03a0a9fe-036a-4028-a134-cca3e50496df"
                  />
                </span>
                {!!invoiceData?.last_valid_invoice_date &&
                  !!invoiceData?.last_valid_invoice_display &&
                  unformatDate(invoiceData?.invoice_date) <
                    unformatDate(
                      invoiceData?.last_valid_invoice_date,
                      'yyyy-MM-dd'
                    ) && (
                    <Tooltip
                      title={`${dictionary.earlier_than_last_invoice}: ${
                        invoiceData?.last_valid_invoice_display
                      } - ${format(
                        unformatDate(
                          invoiceData?.last_valid_invoice_date,
                          'yyyy-MM-dd'
                        ),

                        'PP',
                        lang
                      )}`}
                    >
                      <Warning color="warning" fontSize="small" />
                    </Tooltip>
                  )}
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-2a6aa391-494e-4d4d-9b4b-5368752699fd"
              >
                {dictionary.Invoicing.payment_period}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <CommonPopoverForm
                disabled={!canChange}
                variant={'changeOptionDark'}
                buttonLabel={invoiceData?.billing_option_display}
                propertyLabel={dictionary.Invoicing.payment_period}
                type="select"
                defaultValue={invoiceData?.billing_option || ''}
                onSubmit={values =>
                  handleUpdate('billing_option', values.selectPopover)
                }
                data_testid="CommonPopoverForm-be92e6a1-0740-4392-9df0-e1d3e54be28b"
              >
                {BILLING_OPTION_ITEMS.map(billinOptionField => (
                  <MenuItem
                    value={billinOptionField.value}
                    key={billinOptionField.value}
                    data-testid="MenuItem-c630d895-6180-47ca-bd01-f26929f98b1e"
                  >
                    {billinOptionField.label}
                  </MenuItem>
                ))}
              </CommonPopoverForm>
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-04e34415-11c2-440b-8e36-38b5d83dd37e"
              >
                {dictionary.due_date}
              </Typography>
            </TableCell>
            <TableCell align="right">
              {invoiceData?.due_date || dictionary.none}
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-c59f1250-a446-4362-867a-f43a02a7fbb2"
              >
                {dictionary.bank}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <CommonPopoverForm
                // disabled={isSent}
                disabled={!canChange}
                variant={'changeOptionDark'}
                buttonLabel={invoiceData?.bank ? invoiceData?.bank.name : '---'}
                propertyLabel={dictionary.bank}
                type="select"
                defaultValue={invoiceData?.bank?.id || ''}
                onSubmit={values => handleUpdate('bank', values.selectPopover)}
                data_testid="CommonPopoverForm-2255db89-226b-4405-8fd7-52ce286a4799"
              >
                {banks &&
                  banks?.results.map(bankField => (
                    <MenuItem
                      value={bankField.id}
                      key={bankField.id}
                      data-testid="MenuItem-fcbbf863-84c7-40f3-bd9e-eed892743f42"
                    >
                      {bankField.name}
                    </MenuItem>
                  ))}
              </CommonPopoverForm>
            </TableCell>
          </TableRow>
          <TableRow hover={false} className={classesTable.row}>
            <TableCell>
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-74a68888-a435-4f76-8c4a-75b1543e1c84"
              >
                {dictionary.references}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <CommonPopoverForm
                style={{ whiteSpace: 'pre-wrap' }}
                disabled={!canChange}
                isMultiline
                variant={'changeOptionDark'}
                buttonLabel={invoiceData?.references || '---'}
                propertyLabel={dictionary.references}
                defaultValue={invoiceData?.references || ''}
                onSubmit={values =>
                  handleUpdate('references', values.textPopover)
                }
                data_testid="CommonPopoverForm-500d4a9a-0ecd-40d7-9c3f-1eca5a045b99"
              />
            </TableCell>
          </TableRow>
          {customFields &&
            customFields.results.length > 0 &&
            customFieldsValues &&
            customFields.results.map(cf => (
              <TableRow hover={false} className={classesTable.row} key={cf.pk}>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    data-testid="Typography-907ceb4a-a943-490f-8618-e874916e1d6f"
                  >
                    {cf.name}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <CommonPopoverForm
                    style={{ whiteSpace: 'pre-wrap' }}
                    disabled={isSent}
                    type={
                      cf.field_type === 'i' || cf.field_type === 'f'
                        ? 'number'
                        : cf.field_type === 'd'
                        ? 'calendar'
                        : ''
                    }
                    isMultiline={cf.field_type === 'a'}
                    variant={'changeOptionDark'}
                    buttonLabel={
                      cf.field_type === 'f' || cf.field_type === 'i'
                        ? numberWithCommas(
                            customFieldsValues[cf.pk.toString()] || 0,
                            cf.field_type === 'f' ? 2 : 0
                          )
                        : customFieldsValues[cf.pk.toString()] || '---'
                    }
                    propertyLabel={cf.name || ''}
                    defaultValue={customFieldsValues[cf.pk.toString()] || ''}
                    onSubmit={values =>
                      cf.field_type === 'd'
                        ? updateCustomField(
                            cf.pk,
                            format(values.calendarPopover, 'dd-MM-yyyy')
                          )
                        : updateCustomField(cf.pk, values.textPopover)
                    }
                    data_testid="CommonPopoverForm-94d4e9bb-6db1-4315-8843-27d468047f1b"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DetailTable
