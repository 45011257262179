import { useState } from 'react'
import { Grid } from '@mui/material'

import Navigation from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/External/Navigation'
import Summary from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/External/ContentTabs/Summary'
import ContractorsTable from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/External/ContentTabs/Table'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import queryString from '@utils/queryString'

const TYPE_CO = 'co'
const TYPE_DIRECT_SUB = 'direct-sub'

const External = ({ invoiceData }) => {
  const [activeId, setActiveId] = useState(0)

  const contractorsURL = invoiceData?.project
    ? API_URL.PROJECT.GET_DELIVERABLES_CONTRACTORS(invoiceData?.project) +
      '?' +
      queryString({ invoice: invoiceData?.pk })
    : null
  const { data: contractors, isLoading: isLoadingData } =
    useFetchData(contractorsURL)

  const handleActiveId = id => {
    setActiveId(id)
  }

  const coContractorsData = contractors
    ? contractors?.filter(c => c.type === TYPE_CO)
    : []
  const subContractorsData = contractors
    ? contractors?.filter(c => c.type === TYPE_DIRECT_SUB)
    : []

  return (
    <Grid container>
      <Grid
        item
        xs={2}
        sx={{ borderRight: t => `1px solid ${t.palette.other.stroke}` }}
      >
        <Navigation
          activeId={activeId}
          handleSelect={handleActiveId}
          coContractorsData={coContractorsData}
          subContractorsData={subContractorsData}
        />
      </Grid>
      <Grid item xs={10} p={3}>
        {activeId === 0 ? (
          <Summary
            handleSelect={handleActiveId}
            toMutate={contractorsURL}
            invoiceId={invoiceData.pk}
            isLoadingData={isLoadingData}
            contractors={[...coContractorsData, ...subContractorsData]}
            invoiceData={invoiceData}
          />
        ) : (
          <ContractorsTable
            toMutate={contractorsURL}
            invoiceId={invoiceData.pk}
            isSent={invoiceData?.is_sent}
            contractorId={activeId}
            invoiceData={invoiceData}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default External
