import { FormControl, FormHelperText, Skeleton } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Editor } from '@tinymce/tinymce-react'
import useStyles from './styles'
import { useTranslation } from '@contexts/translation'
//import { postUploadImage } from '@api/upload_image'

export const PAGEBREAK_SEPARATOR_API =
  /<div style="page-break-after:always;">[\s\S]*?<\/div>/g // we get everything that's between the divs to replace it
export const PAGEBREAK_SEPARATOR_API_SPACE =
  /<div style="page-break-after: always;">[\s\S]*?<\/div>/g

// eslint-disable-next-line no-irregular-whitespace
// '<div style="page-break-after:always;"><span style="display:none;"> </span></div>'

export const PAGEBREAK_SEPARATOR_INPUT =
  '<div style="page-break-after:always;"><span style="display:none;">&nbsp;</span></div>'

export const PAGEBREAK_SEPARATOR_TINYMCE = '<!-- pagebreak -->'

// check here for fields changes in tinymce v6 : https://www.tiny.cloud/docs/tinymce/6/6.0-release-notes-core-changes/

// const uploadFile = async blobInfo => {
//   const formData = new FormData()
//   const blob = await blobInfo.blob()
//   const fileName = await blobInfo?.filename()
//   formData.append('file', blob, fileName)

//   const response = await postUploadImage(formData)
//   // console.log(response, blobInfo)
//   // var data = client
//   //   .post(url, blobInfo)
//   //   .then(response => response)
//   //   .catch(error => error.response)

//   if (response?.status === 200) return response.data.location
// }

const ReactHookFormEditor = ({
  name,
  control,
  errorMessage,
  fullWidth = true,
  image,
  height = 250,
  fullscreen = false,
  pagebreak = false,
  loading = false,
  contentStyle = '',
  ...props
}) => {
  const { dictionary, lang } = useTranslation()
  const classes = useStyles()
  const isError = Boolean(errorMessage)

  const languageCodes = {
    fr: 'fr_FR',
    en: 'en_GB',
    es: 'es',
    it: 'it',
    de: 'de',
    nl: 'nl',
    pt: 'pt_PT'
  }
  return !loading ? (
    <FormControl fullWidth={fullWidth} margin="dense">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const handleEditorChange = editor => onChange(editor)

          return (
            <Editor
              apiKey={process.env.NEXT_PUBLIC_TINY_MCE_KEY}
              value={value}
              onEditorChange={handleEditorChange}
              init={{
                language: languageCodes[lang],
                language_url: `/tinymce/langs/${languageCodes[lang]}.js`,
                // min_height: 320,
                // plugins: 'image textpattern lists table ',
                // toolbar: 'undo redo | image',
                height: height,
                menubar: false,
                // convert_fonts_to_spans: true,
                // font_size_style_values: '8pt, 10pt, 12pt, 14pt, 18pt, 24pt, 36pt',
                // font_size_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                font_size_formats:
                  '2px 4px 6px 8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px',
                plugins: [
                  'code',
                  //'image',
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'charmap',
                  // 'print',
                  'preview',
                  'anchor',
                  // 'help',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'insertdatetime',
                  'media',
                  'table',
                  // 'paste',
                  'wordcount',
                  'fullscreen',
                  'pagebreak'
                ],
                toolbar: `undo redo | code | ${
                  fullscreen ? 'fullscreen' : ''
                } | bold italic underline | fontsize | table | \
                      alignleft aligncenter alignright alignjustify | \
                      lineheight bullist numlist outdent indent ${
                        pagebreak ? 'pagebreak' : ''
                      } | forecolor backcolor | ${image ? 'image' : ''}`, // | help`,
                theme_advanced_resizing: true,
                // pagebreak_separator:
                //   '<div style="page-break-after:always;"><span style="display:none;">&nbsp;</span></div>', // originally <!-- pagebreak --> but we adapted to class in backend
                branding: false,
                table_use_colgroups: false,
                table_class_list: [
                  { title: dictionary.default, value: 'tinymce-table-default' },
                  {
                    title: dictionary.tinymce_no_borders,
                    value: 'tinymce-table-no-borders'
                  },
                  {
                    title: dictionary.tinymce_table_outline,
                    value: 'tinymce-table-outline'
                  },
                  {
                    title: dictionary.tinymce_border_top_bottom,
                    value: 'tinymce-table-top-bottom'
                  }
                ],
                line_height_formats:
                  '0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2',
                content_css: '/tinymce/styles/table.css',
                elementpath: false,
                body_class: classes.editor,
                // automatic_uploads: true,
                //file_picker_types: 'image',
                //images_upload_credentials: true,
                // images_upload_url: 'api.ooti.co/upload/',
                // images_upload_handler: async blobInfo => {
                //   const data = await uploadFile(blobInfo)
                //   return data
                //   // return new Promise((resolve, reject) => {
                //   //   uploadFile(blobInfo)
                //   //     .then(data => {
                //   //       resolve(data)
                //   //     })
                //   //     .catch(e => {
                //   //       // showToast({
                //   //       //   type: 'error',
                //   //       //   message: "Error",
                //   //       // });
                //   //       alert('Error', e)
                //   //       reject(e)
                //   //     })
                //   // })
                // },
                pagebreak_split_block: true,
                // images_upload_handler: async function(
                //   blobInfo: any,
                //   success: any,
                //   failure: any
                // ) {
                //   let imageFile = new FormData();
                //   imageFile.append("files[]", blobInfo.blob());

                //   try {

                //     url = process.env.NEXT_PUBLIC_API_URL + '/upload_image/'

                //     const {data} = client.post(url, imageFile).then(response => response)
                //       .catch(error => error.response)

                //     success(data.fileURL);
                //   } catch (error) {
                //     handleResponseError(error);
                //     return;
                //   }
                // },
                // file_picker_callback: function (cb) {
                //   var input = document.createElement('input')
                //   input.setAttribute('type', 'file')
                //   input.setAttribute('accept', 'image/*')
                //   input.onchange = function () {
                //     var file = this.files[0]
                //     var reader = new FileReader()
                //     reader.onload = function () {
                //       var id = 'blobid' + new Date().getTime()
                //       var blobCache =
                //         // eslint-disable-next-line no-undef
                //         tinymce.activeEditor.editorUpload.blobCache
                //       var base64 = reader.result.split(',')[1]
                //       var blobInfo = blobCache.create(id, file, base64)
                //       blobCache.add(blobInfo)

                //       cb(blobInfo.blobUri(), { title: file.name })
                //     }
                //     reader.readAsDataURL(file)
                //   }

                //   input.click()
                // },
                content_style:
                  contentStyle ||
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
              {...props}
            />
          )
        }}
      />
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  ) : (
    <Skeleton width="100%" height={`${height}px`} variant="rectangular">
      <div />
    </Skeleton>
  )
}

export default ReactHookFormEditor
