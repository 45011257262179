import {
  Grid,
  Box,
  Avatar,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Stack,
  IconButton
} from '@mui/material'
import { ChangeCircle, Person as PersonIcon } from '@mui/icons-material'
import { subDays } from 'date-fns'
import { formatDistance, format } from '@utils/date-fns_functions'
import { useTheme } from '@mui/material/styles'

import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'

import useTableStyles from '@styles/table/table'
import queryString from '@utils/queryString'
import CustomPopover from '@components/common/Popover'
const InvoiceSummaryActivity = ({ invoiceId }) => {
  const classesTable = useTableStyles()
  const theme = useTheme()
  const { dictionary, lang } = useTranslation()

  const { data } = useFetchData(
    invoiceId
      ? API_URL.ACTIONS.LIST + '?' + queryString({ invoice: invoiceId })
      : null
  )

  const translateType = type_display => {
    switch (type_display) {
      case 'Invoice item created':
        return dictionary.invoice_item_created
      case 'Invoice item updated':
        return dictionary.invoice_item_updated
      case 'Invoice item deleted':
        return dictionary.invoice_item_deleted
      case 'updated_item_amount':
        return dictionary.invoice_item_amount_updated
      case 'Generated trimester items':
        return dictionary.generated_trimester_items
      case 'Generated tax items':
        return dictionary.generated_tax_items
      case 'Generated reconciliation':
        return dictionary.generated_reconciliation
      default:
        return type_display
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          data-testid="Typography-c2f241af-d39e-4712-ad0c-760228d7a2ff"
        >
          {dictionary.latest_activity}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TableContainer
          component={({ children }) => (
            <Paper
              className={classesTable.container}
              sx={{
                maxHeight: '850px !important',
                border: `1px solid ${theme.palette.other.stroke}`,
                borderRadius: theme => `${theme.spacing(1)} !important`
              }}
            >
              {children}
            </Paper>
          )}
        >
          <Table aria-label="table_project_summary_activity">
            <TableBody>
              {data && !data.results.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      data-testid="Typography-b7b38954-c172-4da1-9211-eada38a2b6f6"
                    >
                      {dictionary.no_activity}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {data &&
                data.results.map(activity => {
                  let newAndOldChanges = []
                  if (activity.diff?.new && activity.diff.old) {
                    newAndOldChanges = [...Object.entries(activity.diff?.new)]
                    Object.entries(activity.diff?.old).forEach(
                      (oldChange, index) => {
                        newAndOldChanges[index].push(oldChange[1])
                      }
                    )
                  }
                  return (
                    <TableRow
                      key={activity.id}
                      hover={false}
                      className={classesTable.row}
                    >
                      <TableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: '4px'
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: '100%' }}
                        >
                          <Avatar
                            aria-label="avatar"
                            src={activity.orguser_photo}
                          >
                            <PersonIcon />
                          </Avatar>
                          <Box ml={2} sx={{ width: '100%' }}>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              data-testid="Typography-3de1b985-c776-4389-875b-e2d15aa0d54a"
                            >
                              {activity.orguser?.display}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.light"
                              data-testid="Typography-39bf9077-418f-4448-a0e0-378cb3687e96"
                            >
                              {translateType(activity.type_display)}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.light"
                              data-testid="Typography-3daa1c93-7d4e-44ad-b53c-18a9e52e1c27"
                            >
                              {formatDistance(
                                subDays(new Date(activity.created_at), 0),
                                new Date(),
                                lang
                              )}
                            </Typography>
                          </Box>
                          {activity.diff && (
                            <CustomPopover
                              buttonLabel={
                                <IconButton
                                  size="small"
                                  data-testid="IconButton-7a2d5b7b-a20f-4a43-addb-209563dd382c"
                                >
                                  <ChangeCircle fontSize="small" />
                                </IconButton>
                              }
                            >
                              <Stack padding={2} justifyContent="center">
                                <Typography
                                  variant="h6"
                                  mb={4}
                                  align="center"
                                  data-testid="Typography-e15b57a5-f3eb-4305-a23f-cad20152098c"
                                >
                                  {dictionary.changes}
                                </Typography>
                                {newAndOldChanges.map((id, activity) => {
                                  if (
                                    (typeof activity[0] === 'object' &&
                                      activity[0] !== null) ||
                                    (typeof activity[1] === 'object' &&
                                      activity[1] !== null) ||
                                    (typeof activity[2] === 'object' &&
                                      activity[2] !== null)
                                  ) {
                                    return
                                  }
                                  if (activity[0] === 'modified_at') {
                                    activity[1] = format(
                                      new Date(activity[1]),
                                      'dd-MM-yyyy'
                                    )

                                    activity[2] = format(
                                      new Date(activity[2]),
                                      'dd-MM-yyyy'
                                    )
                                  }
                                  return (
                                    <Stack
                                      direction="row"
                                      mb={1}
                                      key={activity[0] + id}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        sx={{
                                          width: '33%',
                                          marginRight: 2,
                                          paddingRight: 10
                                        }}
                                        data-testid="Typography-678c7611-164b-4167-8a8f-fdb908311b36"
                                      >
                                        {activity[0]}:
                                      </Typography>

                                      <Typography
                                        variant="body2"
                                        color="text.light"
                                        align="left"
                                        sx={{
                                          textDecoration: 'line-through',
                                          width: '33%',
                                          whiteSpace: 'nowrap',
                                          marginRight: 2
                                        }}
                                        data-testid="Typography-40ef975e-558c-4aac-93d7-6a52afa11280"
                                      >
                                        {`${activity[2]}`}
                                      </Typography>
                                      <Typography
                                        align="left"
                                        variant="body2"
                                        color="text.light"
                                        sx={{
                                          width: '33%',
                                          whiteSpace: 'nowrap'
                                        }}
                                        data-testid="Typography-e2b4bdce-87a6-4510-8a56-9ad1b9e949e7"
                                      >
                                        {`${activity[1]}`}
                                      </Typography>
                                    </Stack>
                                  )
                                })}
                              </Stack>
                            </CustomPopover>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default InvoiceSummaryActivity
