import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Chip,
  Divider,
  List,
  ListItemButton,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useTranslation } from '@contexts/translation'
import PropTypes from 'prop-types'

const FilterActionsSelectorChip = ({
  label = null,
  customLabel = null,
  options = [],
  disabled = false,
  defaultIndex = 0,
  multiple = false,
  className = null,
  deleteIconColor = null,
  withLabel = false,
  size = 'normal',
  icon,
  height = '32px',
  borderRadius = '8px'
}) => {
  const { dictionary } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(
    multiple ? [] : defaultIndex
  )

  useEffect(() => {
    if (!multiple) setSelectedIndex(defaultIndex)
  }, [defaultIndex, multiple])

  const _handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const _handleClickItem = (option, idx) => {
    setSelectedIndex(idx)
    option.handleClick()
    setAnchorEl(null)
  }

  const _handleClickItemMultiple = (option, idx) => {
    let selectedOptions = []
    if (idx === 0) {
      if (selectedIndex.length < options.length) {
        selectedOptions = [...Array(options.length).keys()]
        setSelectedIndex(selectedOptions)
      } else setSelectedIndex([])
    } else {
      if (selectedIndex.includes(idx)) {
        selectedOptions = selectedIndex.filter(i => i !== idx)
        setSelectedIndex(selectedOptions)
      } else {
        selectedOptions = [...selectedIndex, idx]
        setSelectedIndex(selectedOptions)
      }
    }
    const filtered = options.filter((o, i) => selectedOptions.includes(i))
    option.handleClick(filtered.map(f => f.id))
  }

  const _handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'more-actions-popover' : undefined

  return (
    <div>
      <Chip
        size={size}
        disabled={disabled}
        aria-describedby={id}
        onClick={_handleClick}
        sx={{
          backgroundColor: 'white',
          color: theme => theme.palette.text.primary,
          borderRadius: borderRadius,
          border: '1px solid #E0E0E0',
          height: height
        }}
        className={className || ''}
        label={
          customLabel ? (
            customLabel
          ) : (
            <Stack direction="row" alignItems="center" gap={1}>
              {icon}
              <Stack
                direction="row"
                alignItems="center"
                flexWrap="nowrap"
                spacing={1}
                fontSize={13}
              >
                {multiple &&
                  selectedIndex.length > 0 &&
                  selectedIndex.length < options.length &&
                  `${label}: ${selectedIndex.length} ${dictionary.selected}`}
                {multiple &&
                  selectedIndex.length >= options.length &&
                  `${label}: ${dictionary.all} ${dictionary.selected}`}
                {multiple && !selectedIndex.length && options[0]?.label}
                {!multiple &&
                  !withLabel &&
                  (options[selectedIndex]?.label || label)}
                {!multiple &&
                  withLabel &&
                  `${label}: ${options[selectedIndex]?.label}`}
              </Stack>
            </Stack>
          )
        }
        onDelete={_handleClick}
        deleteIcon={
          <KeyboardArrowDown
            aria-describedby={id}
            fontSize="small"
            sx={{ color: deleteIconColor || '' }}
          />
        }
        data-testid="Chip-214f4c6f-8319-4f46-9555-20cb67cc63c0"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={_handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List>
          {options.map((option, idx) => (
            <React.Fragment key={option}>
              <ListItemButton
                key={option}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#F2BB2E14',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#F2BB2E1F'
                    }
                  }
                }}
                selected={selectedIndex === idx}
                onClick={() =>
                  multiple
                    ? _handleClickItemMultiple(option, idx)
                    : _handleClickItem(option, idx)
                }
                disabled={disabled || option.disabled}
                data-testid="ListItemButton-9b84c503-93df-4815-ac3b-feb2bc2af58f"
              >
                {multiple && (
                  <Checkbox
                    checked={selectedIndex.includes(idx)}
                    sx={{ mr: 1 }}
                    data-testid="Checkbox-57880975-61a8-41b9-a908-578684594ad1"
                  />
                )}

                <Typography
                  sx={{ ml: option.tab ? 2 : 0 }}
                  data-testid="Typography-7e2f4fee-a1a2-40bf-af56-bd00caf03161"
                >
                  {option.label}
                </Typography>
              </ListItemButton>
              {option.divider && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Popover>
    </div>
  )
}

export default FilterActionsSelectorChip

FilterActionsSelectorChip.propTypes = {
  label: PropTypes.string,
  customLabel: PropTypes.node,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  defaultIndex: PropTypes.number,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  deleteIconColor: PropTypes.string,
  withLabel: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.node,
  height: PropTypes.string,
  borderRadius: PropTypes.string
}
