import { Stack, Chip, Typography, Button } from '@mui/material'

import { useTranslation } from '@contexts/translation'

import useChipStyles from '@styles/chip/chip'
import numberWithCommas from '@utils/numberWithCommas'

const CustomTitle = ({
  invoiceData,
  length,
  currentIndex,
  setCurrentIndex
}) => {
  const classesChip = useChipStyles()
  const { dictionary, t } = useTranslation()

  const getSteps = () => {
    let step = 1

    if (invoiceData?.is_sent) {
      step = 2
    } else if (invoiceData?.is_scheduled || invoiceData?.is_paid) {
      step = 3
    } else {
      // status.is_valid || status.is_canceled
      step = 1
    }

    return step
  }

  const getChipColor = status => {
    if (status === 'late') return classesChip.error
    if (status === 'not_paid' || status === 'partial')
      return classesChip.warning
    if (status === 'paid') return classesChip.success
    if (status === 'not_sent') return classesChip.secondary
  }

  return (
    <Stack
      direction="row"
      gap={10}
      alignItems="flex-end"
      justifyContent={'space-between'}
    >
      <Stack>
        <Stack spacing={1}>
          <Typography
            variant="overline"
            color="secondary"
            data-testid="Typography-5ecb42e4-a837-46cc-a340-3e874725c7fa"
          >
            {dictionary.step} {getSteps()}/3
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <span data-testid="span-41a970fd-2ef8-4f04-ba0b-e857ac93689d">
            {invoiceData?.code || t('invoicing_invoices_code_undefined')}
          </span>
          <Chip
            size="small"
            label={invoiceData?.status_display}
            className={getChipColor(invoiceData?.status)}
            data-testid="Chip-674b1ee9-080b-49d7-a6db-d3494165a139"
          />

          {invoiceData?.is_scheduled && (
            <Chip
              size="small"
              label={`${dictionary.scheduled}: ${invoiceData?.send_on_date}`}
              className={classesChip.secondary}
              data-testid="Chip-f4701459-ecef-4e86-8a5b-39b0695a6d13"
            />
          )}

          {invoiceData?.is_valid && invoiceData?.balance !== 0 && (
            <Chip
              size="small"
              label={`${dictionary.balance}: ${numberWithCommas(
                invoiceData?.balance,
                2
              )} ${
                invoiceData?.currency_display
                  ? invoiceData.currency_display
                  : ''
              }`}
              className={classesChip.secondary}
              data-testid="Chip-68dd0068-c89b-4e91-af68-7247020282ee"
            />
          )}
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={2} height={34}>
        <Button
          variant="outlined"
          disabled={currentIndex === 0}
          onClick={() =>
            currentIndex === 0 ? null : setCurrentIndex(currentIndex - 1)
          }
          sx={{ width: 160 }}
        >
          {t('invoicing_invoices_previous_invoice')}
        </Button>
        <Button
          variant="outlined"
          disabled={currentIndex === length}
          onClick={() =>
            currentIndex === length ? null : setCurrentIndex(currentIndex + 1)
          }
          sx={{ width: 160 }}
        >
          {t('invoicing_invoices_next_invoice')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default CustomTitle
