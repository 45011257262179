import client from '@config/client'
import API_URL from '@config/services'

export const createCustomfield = (orgPk, payload) => {
  return client
    .post(API_URL.CUSTOMFIELDS.POST_CUSTOMFIELD_LIST(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const editCustomfield = (id, payload) => {
  return client
    .patch(API_URL.CUSTOMFIELDS.PATCH_CUSTOMFIELD(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteCustomfield = id => {
  return client
    .delete(API_URL.CUSTOMFIELDS.DELETE_CUSTOMFIELD(id))
    .then(response => response)
    .catch(error => error.response)
}

export const getCustomfieldValues = (orgPk, type, id) => {
  return client
    .get(
      API_URL.CUSTOMFIELDS.POST_CUSTOMFIELDS_VALUES(orgPk) + `?${type}=${id}`
    )
    .then(response => response)
    .catch(error => error.response)
}

export const postCustomfieldValues = (orgPk, type, id, payload) => {
  return client
    .post(
      API_URL.CUSTOMFIELDS.POST_CUSTOMFIELDS_VALUES(orgPk) + `?${type}=${id}`,
      payload
    )
    .then(response => response)
    .catch(error => error.response)
}
