import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  root: {
    color: theme.palette.text.dark,
    backgroundColor: 'white',
    borderBottom: `2px solid white`,
    textTransform: 'none',
    padding: 0,
    marginRight: 20
  },

  selected: {
    color: theme.palette.dark.main,
    fontWeight: 500,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },

  modalRoot: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    padding: 0,
    marginRight: 10,
    minWidth: 0
  },

  modalSelected: {
    color: theme.palette.dark.main,
    fontWeight: 500,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },

  detailsRoot: {
    fontWeight: 400,
    color: theme.palette.text.dark,
    textTransform: 'none',
    padding: '4px 8px 4px 8px',
    marginRight: 10,
    marginTop: 10,
    '&.MuiButtonBase-root': {
      minHeight: '28px',
      minWidth: 0
    }
  },

  detailsSelected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.highlight.lightPurple,
    borderRadius: '8px',
    fontWeight: 500
  },

  detailsSelectedStandard: {
    fontWeight: 500,
    color: `${theme.palette.text.darker} !important`
  },

  dayOff: {
    color: theme.palette.text.light,
    '&:hover': {
      backgroundColor: theme.palette.dark.main,
      color: theme.palette.elements.white
    }
  },

  dayOffSelected: {
    backgroundColor: theme.palette.elements.main,
    color: theme.palette.text.primary
  }
}))
