import client from '@config/client'
import API_URL from '@config/services'

export const onCreatePayment = (invoiceId, payload) => {
  return client
    .post(
      invoiceId
        ? API_URL.PAYMENTS.POST_PAYMENT(invoiceId)
        : API_URL.PAYMENTS.POST_PAYMENT_PK,
      payload
    )
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const updatePayment = (id, payload) => {
  return client
    .patch(API_URL.PAYMENTS.PATCH_PAYMENT_PK(id), payload)
    .then(response => response)
    .catch(error => error.response)
}
export const updatePaymentInvoice = (id, payload) => {
  return client
    .patch(API_URL.PAYMENTS.PATCH_PAYMENT_INVOIC_EPK(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const onDeletePayment = id => {
  return client
    .delete(API_URL.PAYMENTS.DELETE_PAYMENT_PK(id))
    .then(response => response)
    .catch(error => error.response)
}
