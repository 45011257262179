import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Button,
  CircularProgress,
  Stack,
  ButtonGroup,
  Chip
} from '@mui/material'
import {
  ListAlt as ListAltIcon,
  List as ListIcon,
  Settings as SettingsIcon,
  PictureAsPdf as PDFIcon,
  Payment as PaymentIcon,
  MailOutline as MailIcon,
  ScheduleSend
} from '@mui/icons-material'

import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import { getInvoicePreviewPDF, updateInvoice } from '@api/invoices'

import Summary from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Summary'
import Elements from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Elements'
import Pdfs from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs'
import Settings from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Settings'
import Email from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Email'
import Payment from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Payment'
import CommonModal from '@components/common/Modal/CommonModal'
import CustomTitle from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/CustomTitle'
import { checkPerms } from '@utils/checkPerms'

import queryString from '@utils/queryString'
import useChipStyles from '@styles/chip/chip'
import { useAuth } from '@contexts/auth'
import ButtonTabs from '@components/common/ButtonTabs'
import DeleteModal from '@components/common/DeleteModal'
import { LoadingButton } from '@mui/lab'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import { ButtonSkeleton } from '@components/common/Skeletons'
import MarkAsNotSentForm from './MarkAsNotSentForm'
import { responseHandler } from '@utils/responseHandler'

const TabsComponent = ({
  open,
  id,
  handleOpen,
  handleClose,
  invoicesURL,
  initView = 0,
  approvals = { count: 0, can_approve: false, enabled: false },
  invoicesCountsURL,
  invoicesList = []
}) => {
  const classesChip = useChipStyles()
  const { dictionary } = useTranslation()
  const {
    organization,
    organizationTeam,
    orgSettings,
    permissions,
    membership,
    teamSettings
  } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [view, setView] = useState(initView || 0)
  const [previewPDF, setPreviewPDF] = useState(null)
  const [isMarkAsValid, setIsMarkAsValid] = useState(false)
  const [warningZeroTotal, setWarningZeroTotal] = useState(false)
  const [isMarkAsSent, setIsMarkAsSent] = useState({ open: false, val: false })
  const [isMarkAsNotSent, setIsMarkAsNotSent] = useState(false)
  const [isCreditNote, setIsCreditNote] = useState(false)
  const [isAddPayment, setIsAddPayment] = useState(false)
  const [loading, setLoading] = useState(false)
  const [invoiceId, setInvoiceId] = useState(id)
  const [currentIndex, setCurrentIndex] = useState(
    invoicesList?.indexOf(
      invoicesList?.find(invoice => invoice.id === invoiceId)
    )
  )

  const onClose = () => {
    setIsMarkAsValid(false)
    setIsMarkAsSent({ open: false, val: false })
    setIsCreditNote(false)
    setWarningZeroTotal(false)
  }

  const fetchInvoiceUrl =
    API_URL.INVOICES.GET_INVOICE_PK(invoiceId) +
    '?' +
    queryString({ with_email_logs: true, with_revision_context: true })
  const { data: invoiceData, isLoading: isLoadingData } =
    useFetchData(fetchInvoiceUrl)

  const projectId = invoiceData?.operation?.id || null

  const manage_invoices = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_invoices_project = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    project: projectId,
    perms: permissions
  })
  const manage_draft_invoices = checkPerms({
    perm: ['manage_draft_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_draft_invoices_project = checkPerms({
    perm: ['manage_draft_invoices'],
    org: organization,
    team: organizationTeam,
    project: projectId,
    perms: permissions
  })

  const creditNote = invoiceData?.type === 9

  const permissionsInvoicing = manage_invoices || manage_invoices_project

  const canChange = !invoiceData?.is_sent

  const isSent = invoiceData?.is_sent // && permissionsInvoicing

  const { data: project } = useFetchData(
    projectId ? API_URL.PROJECTS.GET_PROJECT_PK(projectId) : null
  )

  let contracts

  const getInvoicePDF = useCallback(async () => {
    if (invoiceData) {
      // const response = await getInvoicePreviewPDF(
      //   '?' +
      //     queryString({
      //       get_pdf: true,
      //       pk: invoiceData.pk,
      //       hash: Math.random()
      //     })
      // )
      const response = await getInvoicePreviewPDF(invoiceData?.id)
      responseHandler({
        response,
        callback: async () => {
          const fileBlob = new Blob([response.data], {
            type: 'application/pdf'
          })
          const fileURL = URL.createObjectURL(fileBlob)
          setPreviewPDF(fileURL)
        },
        callbackError: () => setPreviewPDF('error'),
        dictionary,
        snackbar: enqueueSnackbar
      })
    }
  }, [invoiceData])

  useEffect(() => {
    getInvoicePDF()
  }, [getInvoicePDF])

  const contractorsEnabled =
    !creditNote &&
    project?.contractor_invoicing_enabled &&
    contracts &&
    contracts.results.filter(c => !c.is_internal).length > 0

  const getActiveView = handleClose => {
    switch (view) {
      case 0:
        return (
          <Summary
            invoicesURL={invoicesURL}
            invoicesCountsURL={invoicesCountsURL}
            invoiceData={invoiceData}
            // taxes={taxes?.results}
            preview={previewPDF}
            projectId={projectId}
            handleClose={handleClose}
            canChange={canChange}
            // taxCode={taxCode}
            isSent={isSent}
            getInvoicePDF={getInvoicePDF}
            onConfirmCreditNote={onConfirmCreditNote}
            handleUpdateInvoiceField={handleUpdateInvoiceField}
            isCreditNote={isCreditNote}
            setIsCreditNote={setIsCreditNote}
          />
        )

      case 1:
        return (
          <Elements
            invoiceData={invoiceData}
            setPreviewPDF={setPreviewPDF}
            contractorsEnabled={contractorsEnabled}
            fetchInvoiceUrl={fetchInvoiceUrl}
            getInvoicePDF={getInvoicePDF}
            canChange={canChange}
            // taxes={taxes?.results}
            project={project}
          />
        )

      case 4:
        return (
          <Pdfs
            contractorsEnabled={contractorsEnabled}
            invoiceData={invoiceData}
            fetchInvoiceUrl={fetchInvoiceUrl}
            projectId={projectId}
            project={project}
            preview={previewPDF}
          />
        )

      case 5:
        return (
          <Email
            invoiceData={invoiceData}
            invoicesURL={invoicesURL}
            contractorsEnabled={contractorsEnabled}
            fetchInvoiceUrl={fetchInvoiceUrl}
            project={project}
            invoicesCountsURL={invoicesCountsURL}
          />
        )

      case 6:
        return (
          <Payment
            invoiceData={invoiceData}
            setIsAddPayment={setIsAddPayment}
            isAddPayment={isAddPayment}
            projectId={projectId}
            invoicesURL={invoicesURL}
            fetchURL={fetchInvoiceUrl}
            invoicesCountsURL={invoicesCountsURL}
          />
        )

      case 7:
        return (
          <Settings
            invoiceData={invoiceData}
            invoicesURL={invoicesURL}
            // taxCode={taxCode}
            fetchInvoiceUrl={fetchInvoiceUrl}
            isSent={invoiceData?.is_sent}
            canChange={canChange}
            // taxes={taxes?.results}
            getInvoicePDF={getInvoicePDF}
          />
        )

      default:
        return <div />
    }
  }

  useEffect(async () => {
    if (invoiceData) getInvoicePDF(invoiceData.id)
  }, [invoiceData?.id])

  const tabs = [
    {
      isVisible: true,
      startIcon: ListAltIcon,
      onClick: () => {
        getInvoicePDF(invoiceData?.pk)
        setView(0)
      },
      selected: view === 0,
      text: dictionary.summary
    },
    {
      isVisible: true,
      startIcon: ListIcon,
      onClick: () => setView(1),
      selected: view === 1,
      text: dictionary.elements
    },
    {
      isVisible: true,
      startIcon: PDFIcon,
      onClick: () => setView(4),
      selected: view === 4,
      text: dictionary.pdf
    },
    {
      isVisible: true,
      startIcon: MailIcon,
      onClick: () => setView(5),
      selected: view === 5,
      text: dictionary.emails
    },
    {
      isVisible: invoiceData?.is_valid && invoiceData?.is_sent,
      startIcon: PaymentIcon,
      onClick: () => {
        if (invoiceData?.balance) {
          setIsAddPayment(true)
        }
        setView(6)
      },
      selected: view === 6,
      text: dictionary.payment
    },
    {
      isVisible: true,
      startIcon: SettingsIcon,
      onClick: () => {
        setView(7)
      },
      selected: view === 7,
      text: dictionary.invoice_configuration
    }
  ]

  const handleUpdateInvoiceField = async payload => {
    setLoading(true)
    const response = await updateInvoice(invoiceData.pk, payload)

    await responseHandler({
      response,
      callback: async () => {
        setPreviewPDF(null)
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    return response
  }

  const onConfirmMarkAs = async (field, value) => {
    const response = await handleUpdateInvoiceField({ [field]: value })

    await responseHandler({
      response,
      callback: async () => {
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesCountsURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })
    onClose()
  }

  const onConfirmCreditNote = async () => {
    setLoading(true)
    const response = await updateInvoice(invoiceData.pk, { is_closed: true })
    responseHandler({
      response,
      callback: async () => {
        setPreviewPDF(null)
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesURL)
        await mutate(invoicesCountsURL)
        if (response?.data.credit_note_url) {
          onClose()
          setLoading(false)
          const creditNoteURL = response?.data.credit_note_url.split('/') || []
          const creditNoteId = creditNoteURL[creditNoteURL?.length - 2]
          handleOpen(creditNoteId, 'not_valid', false, 0)
          return
        }
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    onClose()
  }

  const toSendActions =
    invoiceData?.is_valid &&
    !invoiceData?.is_sent &&
    !invoiceData?.is_scheduled &&
    permissionsInvoicing

  const scheduledActions =
    invoiceData?.is_valid &&
    !invoiceData?.is_sent &&
    invoiceData?.is_scheduled &&
    permissionsInvoicing

  const sentActions =
    invoiceData?.is_valid && invoiceData?.is_sent && permissionsInvoicing

  const canSend =
    invoiceData?.can_send_email &&
    invoiceData?.status !== 'not_valid' &&
    orgSettings.invoice_sending_enabled &&
    invoiceData?.invoice_email_template

  // const canMarkAsValid = approvals.enabled
  //   ? !(invoiceData?.items?.length === 0) &&
  //     invoiceData?.client_address &&
  //     approvals.count >= invoiceData?.approvers?.length
  //   : // && teamSettings?.invoicing_is_setup
  //     !(invoiceData?.items?.length === 0) && invoiceData?.client_address
  // && teamSettings?.invoicing_is_setup

  let conditionsTooltip = []
  if (invoiceData?.items?.length === 0)
    conditionsTooltip.push(dictionary.must_have_items)
  if (!invoiceData?.client_address)
    conditionsTooltip.push(dictionary.must_have_client_address)
  if (approvals.enabled && approvals.count < invoiceData?.approvers?.length)
    conditionsTooltip.push(dictionary.must_be_approved)
  if (!teamSettings?.invoicing_is_setup)
    conditionsTooltip.push(dictionary.must_validate_setup)
  // const tooltipText = conditionsTooltip.join(', ')
  // invoiceData?.items?.length === 0
  //   ? dictionary.must_have_items
  //   : !invoiceData?.client_address
  //   ? dictionary.must_have_client_address
  //   : approvals.enabled && approvals.count < invoiceData?.approvers?.length
  //   ? dictionary.must_be_approved
  //   : ''

  const approvalsEnabled = useMemo(
    () =>
      invoiceData?.operation?.invoice_approval_enabled &&
      !invoiceData?.is_valid,
    [invoiceData]
  )
  const approvalsNeeded = useMemo(
    () => invoiceData?.operation?.invoice_approvals_count,
    [invoiceData?.operation?.invoice_approvals_count]
  )
  const approvalsCount = useMemo(
    () => invoiceData?.approvals_count,
    [invoiceData?.approvals_count]
  )
  const canApprove = useMemo(
    () =>
      invoiceData?.operation?.invoice_approvers?.includes(membership?.orguser),
    [invoiceData?.operation?.invoice_approvers, membership?.orguser]
  )
  const approvedByMe = useMemo(
    () => invoiceData?.approvers?.includes(membership?.orguser),
    [invoiceData?.approvers, membership?.orguser]
  )

  const canMarkAsValid = useMemo(() => {
    return approvalsEnabled ? approvalsCount >= approvalsNeeded : true
  }, [approvalsCount, approvalsEnabled, approvalsNeeded])

  // const needApprovals = approvals.enabled && canChange

  useEffect(() => {
    if (currentIndex >= 0) {
      setInvoiceId(invoicesList[currentIndex]?.id)
    }
  }, [currentIndex])

  return (
    <>
      <CommonModal
        title={
          <CustomTitle
            invoiceData={invoiceData}
            length={invoicesList?.length - 1}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        }
        titleWidth="100%"
        onClose={handleClose}
        open={open}
        maxWidth="xl"
        noPadding
        isAlignTop
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            sx={{ px: 2, pt: 2 }}
          >
            <ButtonTabs spacing={2} tabs={tabs} />
          </Grid>
          <Grid
            item
            xs={12}
            align="right"
            sx={{
              borderBottom: t => `1px solid ${t.palette.other.stroke}`,
              p: 2
            }}
          >
            {isLoadingData && <ButtonSkeleton count={2} />}
            {!isLoadingData && (
              <Stack direction="row" spacing={2} sx={{ width: 'fit-content' }}>
                {!sentActions &&
                  (previewPDF && previewPDF !== 'error' ? (
                    <a
                      href={previewPDF}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        variant="outlined"
                        color="inherit"
                        data-testid="Button-1a4f6c79-a4c1-4bce-9f4c-5d2588034ae2"
                      >
                        {dictionary.Invoicing.open_pdf}
                      </Button>
                    </a>
                  ) : (
                    <Button
                      variant="outlined"
                      color="inherit"
                      disabled
                      data-testid="Button-cdd999fd-db42-4771-a5a6-7d4a26a125a6"
                    >
                      {dictionary.Invoicing.open_pdf}
                    </Button>
                  ))}

                {
                  !invoiceData?.is_valid && (
                    // <Tooltip title={tooltipText}>
                    //   <span>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() => {
                        if (invoiceData.total === 0) {
                          setWarningZeroTotal(true)
                        } else {
                          setIsMarkAsValid(true)
                        }
                      }}
                      // disabled={elements?.results?.length === 0}
                      disabled={!canMarkAsValid}
                      data-testid="Button-9ea8195e-f515-457d-8fea-7702b0277eff"
                    >
                      {dictionary.mark_as_valid}
                    </Button>
                  )
                  //   </span>
                  // </Tooltip>
                }

                {toSendActions && (
                  <FilterActionsSelector
                    isMenu
                    defaultIndex={0}
                    customStyles={{
                      buttonRoot: {
                        backgroundColor: theme => theme.palette.primary.main,
                        color: 'white',
                        border: 'none',
                        '&:hover': {
                          backgroundColor: theme => theme.palette.primary.main,
                          border: 'none'
                        }
                      }
                    }}
                    label={dictionary.send}
                    options={[
                      {
                        label: dictionary.send,
                        handleClick: () => null
                      },
                      {
                        label: dictionary.Invoicing.mark_as_sent,
                        handleClick: () =>
                          setIsMarkAsSent({ open: true, val: true })
                      },
                      {
                        disabled: !canSend,
                        label: dictionary.send_email,
                        handleClick: () => setView(5)
                      }
                    ]}
                  />
                )}

                {scheduledActions && (
                  <Chip
                    className={classesChip.secondary}
                    icon={
                      <ScheduleSend
                        fontSize="small"
                        style={{ color: 'white' }}
                      />
                    }
                    onClick={() => setView(5)}
                    label={dictionary.scheduled}
                    data-testid="Chip-c84d533d-dac1-49e3-83c8-9e04b56e0a40"
                  />
                )}

                {sentActions && (
                  <>
                    <Button
                      startIcon={<PaymentIcon />}
                      onClick={() => {
                        setIsAddPayment(true)
                        setView(6)
                      }}
                      data-testid="Button-1dfe2489-db58-4a86-b2f2-786e8bbef791"
                    >
                      {dictionary.pay}
                    </Button>
                    <FilterActionsSelector
                      isMenu
                      defaultIndex={0}
                      options={[
                        {
                          label: dictionary.actions,
                          handleClick: () => null
                        },
                        {
                          label: dictionary.Invoicing.mark_as_not_sent,
                          handleClick: () => setIsMarkAsNotSent(true)
                          // setIsMarkAsSent({ open: true, val: false })
                        },
                        {
                          label: dictionary.create_credit_note,
                          handleClick: () => setIsCreditNote(true)
                        },
                        {
                          label: invoiceData?.is_litigious
                            ? dictionary.mark_as_not_litigious
                            : dictionary.mark_as_litigious,
                          handleClick: () =>
                            onConfirmMarkAs(
                              'is_litigious',
                              invoiceData?.is_litigious ? false : true
                            )
                        },
                        {
                          disabled: previewPDF === 'error',
                          label: (
                            <a
                              href={previewPDF ? previewPDF : 'https://ooti.co'}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {dictionary.Invoicing.open_pdf}
                            </a>
                          ),

                          handleClick: () => null
                        }
                      ]}
                    />
                  </>
                )}

                {approvalsEnabled && (
                  <ButtonGroup
                    size="small"
                    color="secondary"
                    disabled={!canApprove}
                  >
                    <LoadingButton
                      loading={loading}
                      onClick={() => {
                        const currentApprovers = invoiceData?.approvers || []
                        handleUpdateInvoiceField({
                          approvers: approvedByMe
                            ? currentApprovers.filter(
                                approver => approver !== membership?.orguser
                              )
                            : [...currentApprovers, membership?.orguser]
                        })
                      }}
                      data-testid="LoadingButton-5c12750a-83a6-4860-924e-d01dac05db43"
                    >
                      {approvedByMe ? dictionary.cancel : dictionary.approve}
                    </LoadingButton>
                    <Button data-testid="Button-dd8db74c-6a9b-4acb-9722-e475aa35baf0">
                      {`${approvalsCount}/${approvalsNeeded}`}
                    </Button>
                  </ButtonGroup>
                )}
              </Stack>
            )}
          </Grid>
          {isLoadingData && (
            <Grid item xs={12} align="center">
              <CircularProgress color="secondary" sx={{ m: 10 }} />
            </Grid>
          )}

          {!isLoadingData && invoiceData && (
            <Grid item xs={12} container>
              {getActiveView(handleClose)}
            </Grid>
          )}
        </Grid>

        <DeleteModal
          open={
            isMarkAsValid ||
            isMarkAsSent.open ||
            isCreditNote ||
            warningZeroTotal
          }
          onClose={onClose}
          onConfirm={
            isMarkAsValid
              ? () => onConfirmMarkAs('is_valid', true)
              : isMarkAsSent.open
              ? () => onConfirmMarkAs('is_sent', isMarkAsSent.val)
              : isCreditNote
              ? onConfirmCreditNote
              : warningZeroTotal
              ? () => setIsMarkAsValid(true)
              : null
          }
          description={
            isMarkAsValid
              ? dictionary.delete_invoice_info
              : isMarkAsSent.open
              ? dictionary.mark_as_sent_confirm
              : isCreditNote
              ? dictionary.credit_note_confirm
              : warningZeroTotal
              ? dictionary.zero_total_confirm
              : ''
          }
          loading={loading}
        />
      </CommonModal>

      <CommonModal
        open={isMarkAsNotSent}
        title={dictionary.Invoicing.mark_as_not_sent}
        onClose={() => setIsMarkAsNotSent(false)}
        maxWidth={'sm'}
      >
        <MarkAsNotSentForm
          updateInvoice={handleUpdateInvoiceField}
          onClose={() => setIsMarkAsNotSent(false)}
        />
      </CommonModal>
    </>
  )
}

export default TabsComponent
