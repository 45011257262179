import React from 'react'
import { List, ListItemButton, ListItemIcon, Typography } from '@mui/material'
import {
  LocalAtm,
  Person,
  Calculate,
  InsertDriveFile
} from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'

const Navigation = ({ view, handleNavigation }) => {
  const { dictionary } = useTranslation()

  const MENU_ITEMS = [
    {
      id: 0,
      title: dictionary.client,
      icon: <Person />
    },
    {
      id: 1,
      title: dictionary.invoice,
      icon: <InsertDriveFile />
    },
    {
      id: 2,
      title: dictionary.tax,
      icon: <Calculate />
    },
    {
      id: 3,
      title: dictionary.payment,
      icon: <LocalAtm />
    }
  ]

  return (
    <List>
      {MENU_ITEMS.map(item => {
        const isSelected = item.id === view

        return (
          <ListItemButton
            key={item.id}
            onClick={() => handleNavigation(item.id)}
            selected={isSelected}
            sx={{
              pl: 0,
              py: '8px',
              '&.Mui-selected': {
                '&:hover': {
                  background: theme => theme.palette.secondary.light
                },
                background: theme => theme.palette.secondary.light,

                boxShadow: theme =>
                  `inset -2px 0px 0px ${theme.palette.secondary.main}`
              }
            }}
            data-testid="ListItemButton-36e659d0-0ade-4a65-8aee-9ba2937ea0c6"
          >
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '28px',
                mr: '12px',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {item.icon}
            </ListItemIcon>
            <Typography
              variant={isSelected ? 'subtitle2' : 'body2'}
              color={isSelected ? 'secondary.main' : 'text.primary'}
              data-testid="Typography-4e745feb-ae40-4850-87e4-aac5845d27fd"
            >
              {item.title}
            </Typography>
          </ListItemButton>
        )
      })}
    </List>
  )
}

export default Navigation
