import React, { useState } from 'react'
import { Grid } from '@mui/material'
import {
  PeopleOutlined as PeopleOutlineIcon,
  RemoveRedEye as RemoveRedEyeIcon
} from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'

import ElementsTable from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Elements/Table'
import External from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/External'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import ButtonGroupTabs from '@components/common/ButtonGroupTabs'

const Elements = ({
  invoiceData,
  contractorsEnabled,
  fetchInvoiceUrl,
  getInvoicePDF,
  canChange,
  setPreviewPDF
}) => {
  const { dictionary } = useTranslation()

  const [tabView, setTabsView] = useState(1)

  const [isInvoiceHistory, setIsInvoiceHistory] = useState({
    open: false,
    invoicePk: null
  })

  const historyInvoiceURL = isInvoiceHistory.open
    ? API_URL.INVOICES.GET_INVOICE_PK(isInvoiceHistory.invoicePk)
    : null
  const { data: historyInvoice } = useFetchData(historyInvoiceURL)

  const tabs = [
    {
      isVisible: true,
      startIcon: <RemoveRedEyeIcon />,
      onClick: () => setTabsView(1),
      selected: tabView === 1,
      text: dictionary.internal_team
    },
    {
      isVisible: true,
      startIcon: <PeopleOutlineIcon />,
      onClick: () => setTabsView(2),
      selected: tabView === 2,
      text: dictionary.co_sub_contractors
    }
  ]

  const getTabsView = () => {
    return <ButtonGroupTabs tabs={tabs} />
  }

  return (
    <Grid container>
      {contractorsEnabled && (
        <Grid
          item
          xs={12}
          sx={{
            px: 3,
            py: 2,
            borderBottom: t => `1px solid ${t.palette.other.stroke}`
          }}
        >
          {getTabsView()}
        </Grid>
      )}
      <Grid item xs={12}>
        {tabView === 1 && (
          <Grid container spacing={3} p={3}>
            <ElementsTable
              invoiceData={isInvoiceHistory.open ? historyInvoice : invoiceData}
              isInvoiceHistory={isInvoiceHistory.open}
              setIsInvoiceHistory={setIsInvoiceHistory}
              isSent={isInvoiceHistory.open ? true : invoiceData?.is_sent}
              setPreviewPDF={setPreviewPDF}
              fetchInvoiceUrl={fetchInvoiceUrl}
              getInvoicePDF={getInvoicePDF}
              canChange={isInvoiceHistory.open ? false : canChange}
            />
          </Grid>
        )}
        {tabView === 2 && <External invoiceData={invoiceData} />}
      </Grid>
    </Grid>
  )
}

export default Elements
